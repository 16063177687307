import React, { useState } from "react";
import { Card, Button, Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Accordion from "react-bootstrap/Accordion";
import Loader from "../../Components/Common/Loader";

export default function ReceiveFromFinal() {
  const [searchMixNo, setSearchMixNo] = useState("");
  const [finalStoneList, setFinalStoneList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const SearchMixNoFinalStoneData = (e) => {
    setIsLoading(true);
    api
      .get(`Stone/FindIssuedFinalStoneByMixNo?MixNo=${searchMixNo}`)
      .then((result) => {
        let Mixdata = result.data.data;

        if (Mixdata !== null) {
          if (finalStoneList.length > 0) {
            let MixNoIsInList = finalStoneList.some(
              (stone) => stone.previousStoneID === Mixdata[0].previousStoneID
            );

            if (MixNoIsInList) {
              toast.error("FinalStone is already in the list");
              return;
            } else {
              setFinalStoneList((prevData) => [...prevData, ...Mixdata]);
            }
          } else {
            setFinalStoneList((prevData) => [...prevData, ...Mixdata]);
          }
        }

        setSearchMixNo("");
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const OnEnterSearchMixNoFinalStoneData = (e) => {
    if (e.key === "Enter") {
      SearchMixNoFinalStoneData();
    }
  };

  const removeData = (MixNoToRemove) => {
    const updatedList = finalStoneList.filter(
      (stone) => stone.stoneNo !== MixNoToRemove
    );
    setFinalStoneList(updatedList);
  };

  const ReceiveFinalStone = () => {
    setIsLoading(true);
    api
      .post(`Stone/ReceiveFinalStoneAndAddFinalStoneSummary`, finalStoneList)
      .then((result) => {
        toast.success(result.data.message);
        setFinalStoneList([]);
        setSearchMixNo("");
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      }).finally(() => {
        setIsLoading(false);

      })
  };

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Card>
        <Card.Header>
          <span>Receive From Final</span>
        </Card.Header>
        <Card.Body>
          <div className="row align-middle d-flex justify-content-center align-items-center">
            <div className="col-md-2 col-sm-2 col-lg-2 d-flex justify-content-end align-items-center">
              <label htmlFor="Find_no">
                <div className="d-flex align-items-center">
                  {" "}
                  <span>Find MixNo/ BagNo : </span>
                  {/* <button
                    type="button"
                    style={{ marginLeft: "20px" }}
                    className="btn btn-primary"
                    //onClick={toggleBarcodeScanner}
                  >
                    <i className="fa fa-close"></i>
                  </button> */}
                </div>
              </label>
            </div>
            <div className="col-md-4 col-sm-4 col-lg-4">
              <input
                type="text"
                className="form-control"
                id="searchMicNo"
                placeholder="Search Mix No or BagNo"
                value={searchMixNo}
                onChange={(e) => {
                  setSearchMixNo(e.target.value);
                  // PrintMixStoneSourceReport();
                }}
                onKeyDown={OnEnterSearchMixNoFinalStoneData}
              />
            </div>
            <div className="col-md-1 col-sm-1 col-lg-1 searchBtn">
              <button
                type="button"
                className="align-items-center btn btn-primary"
                onClick={(e) => SearchMixNoFinalStoneData()}
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <strong> Stone List </strong>
            </Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Mix No</th>
                    <th>Stone Type</th>
                    <th>Pcs</th>
                    <th>Length</th>
                    <th>Width</th>
                    <th>Height</th>
                    <th>Weight</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {finalStoneList.length > 0 ? (
                    finalStoneList.map((stone, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{stone.stoneNo}</td>
                        <td>{stone.stoneType}</td>
                        <td>{stone.pcs}</td>
                        <td>{stone.length}</td>
                        <td>{stone.width}</td>
                        <td>{stone.height}</td>
                        <td>{stone.weight != null ? stone.weight.toFixed(2) : 0}</td>
                        <td>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => removeData(stone.previousMixNo)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9">No stones available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>
      <Card>
        <Card.Footer>
          <Button
            type="button"
            className="align-items-center btn btn-primary mb-20px mb-1"
            onClick={ReceiveFinalStone}
          >
            save
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
}
