import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import Loader from "../../Components/Common/Loader";
import {
  PrintStoneSticker,
  PrintPreviewStoneDetails,
} from "../../Utils/commonfunctions";
import ImageUploadModalComponent from "../../Components/Common/ImageUploadModalComponent";
import ImageViewerModalComponent from "../../Components/Common/ImageViewerModalComponent";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { Button, Col, InputGroup, Form } from "react-bootstrap";
import { GetPre7DayDate, getCurrentDate } from "../../Utils/commonfunctions";

export default function SplitStone() {
  const [SplitStoneData, setSplitStoneData] = useState([]);
  const [options, setOptions] = useState([]);
  // const [optionsStoneNo, setOptionsMixNo] = useState([]);
  const [selectedRowID, setSelectedRowID] = useState([]); // Selected Row IDs state
  // Selected Row IDs state
  const [numOfRows, setNumOfRows] = useState([]);
  const [SplitStoneList, setSplitStoneList] = useState([]);
  const [stoneType, setStoneType] = useState("");
  const [stoneNo, setStoneNo] = useState("");
  const [defaultSetLength, setDefaultSetLength] = useState(0);
  const [defaultSetWidth, setDefaultSetWidth] = useState(0);
  const [stoneNoData, setStoneNoData] = useState([]);

  //edit SplitStone Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [SplitStoneDataForPopup, setSplitStoneDataForPopup] = useState([]);
  const [findStoneNO, setFindStoneNo] = useState("ST-");

  //Insert Image
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  //Image Viewer
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedBatchNo, setSelectedBatchNo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [totalWeight, setTotalWeight] = useState(0);

  const emtSearchFormData = {
    start_date: GetPre7DayDate(),
    end_date: getCurrentDate(),
  };

  // for date range picker 
  const [dateData, setDateData] = useState(emtSearchFormData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDateData({ ...dateData, [name]: value });
  };


  useEffect(() => {
    getSplitStone();
    getStoneTypeName();
    // getStoneNoList();
  }, []);

  //Fetch SplitStone Type Name For DD
  const getStoneTypeName = () => {
    api
      .get("StoneType/GetStoneTypeName")
      .then((result) => {
        setOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fetch all SplitStones
  const getSplitStone = () => {
    api
      .get(`Stone/AllStoneWithBag?START_DATE=${dateData.start_date}&END_DATE=${dateData.end_date}`)
      .then((result) => {
        setSplitStoneData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowInputChange = (index, field, value) => {
    const updatedList = [...SplitStoneList];
    updatedList[index] = {
      ...updatedList[index],
      stoneID: stoneNo,
      stoneType: stoneType,
      [field]: value,
    };
    setSplitStoneList(updatedList);

    // Calculate total weight whenever weight changes
    if (field === "weight") {
      const newTotalWeight = updatedList.reduce(
        (accumulator, currentValue) =>
          accumulator + (parseFloat(currentValue.weight) || 0),
        0
      );
      setTotalWeight(newTotalWeight);
    }
  };

  // Insert & Update Data
  const formik = useFormik({
    initialValues: {
      stone_id: 0,
      stone_Type: stoneType,
      length: 0.0,
      width: 0.0,
      height: 0.0,
      weight: 0.0,
      in_stock: false,
      received: false,
      pcs: 0,
      lot_type: "",
      mix_no: "",
    },
    validationSchema: Yup.object({
      stone_Type: Yup.string().required("Select SplitStone Type"),
      lot_type: Yup.string().required("Select Lot Type"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
  });

  const restData = () => {
    setSplitStoneList([]);
    setStoneNo("");
    setStoneType("");
    setNumOfRows([]);
    setStoneNoData([]);
    setFindStoneNo("");
  };

  // Inserting SplitStone data
  const InsertSplitStone = () => {
    debugger;
    setIsLoading(true);
    if (findStoneNO === "") {
      toast.error("Please Enter a Stone No.");
      setIsLoading(false);
      return;
    }
    if (!stoneType) {
      toast.error("Please Select a Stone Type.");
      setIsLoading(false);
      return;
    }
    if (numOfRows <= 0) {
      toast.error("Please Enter a Valid Number of Rows.");
      setIsLoading(false);
      return;
    }

    const totalWeight = SplitStoneList.reduce((acc, stone) => {
      const weight = parseFloat(stone.weight);
      return isNaN(weight) ? acc : acc + weight;
    }, 0);

    const isASIStone = findStoneNO.toUpperCase().startsWith("ASI");

    if (
      isASIStone &&
      (totalWeight > stoneNoData[0].weight || numOfRows > stoneNoData[0].pcs)
    ) {
      toast.error("Please Enter a Valid Pcs And Weight");
      setIsLoading(false);
      return;
    } else if (!isASIStone && totalWeight > stoneNoData[0].weight) {
      toast.error("Please Enter a Valid Weight");
      setIsLoading(false);
      return;
    }
    api
      .post(`Stone/InsertSplitStone`, SplitStoneList)
      .then((result) => {
        toast.success(result.data.message);
        getSplitStone();
        restData();
        printNewInsertedSticker(result.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      });
  };

  // Delete SplitStone
  const deleteSplitStone = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`SplitStone/Delete?SplitStoneID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getSplitStone();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const exportToExcel = () => {
    setIsLoading(true);

    setTimeout(async () => {
      try {
        const fileName = "SplitStone.xlsx";

        // Format the data to be exported
        const formattedData = SplitStoneData.map((item) => ({
          StoneNo: item.stoneNo,
          LotNo: item.batchNo,
          StoneType: item.stoneType,
          Length: item.length,
          Width: item.width,
          Height: item.height,
          Weight: item.weight,
          Pcs: item.pcs,
        }));

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Issue Machine List");

        // Set up the columns
        worksheet.columns = [
          { header: "Stone No", key: "StoneNo", width: 20 },
          { header: "Lot No", key: "LotNo", width: 20 },
          { header: "Stone Type", key: "StoneType", width: 20 },
          { header: "Length", key: "Length", width: 15 },
          { header: "Width", key: "Width", width: 15 },
          { header: "Height", key: "Height", width: 15 },
          { header: "Weight", key: "Weight", width: 15 },
          { header: "PCS", key: "Pcs", width: 10 },
        ];

        // Add the rows to the worksheet
        worksheet.addRows(formattedData);

        // Style the header row
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF4F81BD" },
          };
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });

        // Adjust column widths based on content length
        worksheet.columns.forEach((column) => {
          let maxLength = column.header.length;
          formattedData.forEach((row) => {
            const cellValue = row[column.key];
            if (cellValue) {
              maxLength = Math.max(maxLength, cellValue.toString().length);
            }
          });
          column.width = maxLength + 2; // Add padding
        });

        // Export the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
        // Handle any error here, such as showing an error message to the user
      } finally {
        setIsLoading(false);
      }
    }, 1000);
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      width: 50,
      headerClass: "centered-header",
      pinned: "left",
    },
    {
      headerName: "Stone No",
      field: "stoneNo",
      width: "150px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Lot No",
      field: "batchNo",
      width: "150px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Stone Type",
      field: "stoneType",
      width: "150px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Length",
      field: "length",
      width: "150px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Width",
      field: "width",
      width: "150px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Height",
      field: "height",
      width: "150px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Weight",
      field: "weight",
      width: "150px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Pcs",
      field: "pcs",
      width: "150px",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Attached",
      field: "batchNo",
      width: "150px",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="attach-outline"
          class="iconsFonts attachIcon"
          onClick={() => popUpForImage(data)}
        ></ion-icon>
      ),
    },
    {
      headerName: "View",
      field: "batchNo",
      width: "100px",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="eye-outline"
          class="iconsFonts attachIcon"
          onClick={() => popUpForViewImage(data.stoneNo)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Print",
      width: 100,
      field: "stoneNo",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="print-outline"
          class="iconsFonts"
          // onClick={() => PrintStoneSticker(data.stoneNo, data.stoneProcessNo)}
          onClick={() => PrintStoneSticker(data.stoneNo, data.stoneProcessNo)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Edit",
      width: 100,
      field: "stoneID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => editSplitStoneModal(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Delete",
      width: 100,
      field: "stoneID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteSplitStone(value)}
        ></ion-icon>
      ),
    },
  ];

  // Insert Image
  const popUpForImage = (data) => {
    setImageModalOpen(true);
    setModalData(data);
  };

  const closeModal = () => {
    setImageModalOpen(false);
  };
  // Insert Image End

  //Image Viewer Start
  const popUpForViewImage = (batchNo) => {
    setSelectedBatchNo(batchNo);
    setIsImageViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsImageViewerOpen(false);
    setSelectedBatchNo(0);
  };

  //SplitStone edit start
  const editSplitStoneModal = (id) => {
    api
      .get(`Stone/ByID?StoneID=${id}`)
      .then((result) => {
        const SplitStoneData = result.data.data;
        setSplitStoneDataForPopup(SplitStoneData);
        setIsModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const updateSplitStoneDetails = () => {
    api
      .patch(`Stone/Edit`, SplitStoneDataForPopup)
      .then((result) => {
        getSplitStone();
        setIsModalOpen(false);
        toast.success(result.data.message);
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while updating the SplitStone");
      });
  };
  //SplitStone edit end

  const handleCheckboxSelection = (event) => {
    setSelectedRowID([]);
    const selectedRows = event.api.getSelectedRows();
    const selectedRowIDs = Array.isArray(selectedRows)
      ? selectedRows.map((row) => row.stoneNo)
      : [];
    setSelectedRowID(selectedRowIDs);
  };

  const printSelectedRows = () => {
    api
      .post(`Stone/PrintMultiStoneSticker`, selectedRowID)
      .then((result) => {
        const selectedStonesData = result.data.data;
        HandelPrintStone(selectedStonesData);
        setSelectedRowID([]);
        getSplitStone();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const printNewInsertedSticker = (data) => {
    api
      .post(`Stone/PrintMultiStoneSticker`, data)
      .then((result) => {
        const selectedStonesData = result.data.data;
        HandelPrintStone(selectedStonesData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const HandelPrintStone = (selectedStonesData) => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    iframeDocument.write(
      '<html><head><style>@page{margin: 0;}</style><title>Report</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"></head><body>'
    );
    ReactDOM.render(
      <>
        {selectedStonesData.map((stoneDetail, index) => (
          <React.Fragment key={index}>
            <PrintPreviewStoneDetails stoneDetail={stoneDetail} />
          </React.Fragment>
        ))}
      </>,
      iframeDocument.body
    );
    iframeDocument.write("</body></html>");
    iframeDocument.close();
    iframe.contentWindow.print();
    // setTimeout(() => {
    //   document.body.removeChild(iframe);
    // }, 1000);
  };

  const StoneNoSetAndGetData = (e) => {
    debugger
    if (e.key === "Enter") {
      api
        .get(`Stone/ByStoneNo?StoneNo=${findStoneNO}`)
        .then((result) => {
          setStoneNoData(result.data.data);
          setStoneNo(result.data.data[0].stoneID);
          setStoneType(result.data.data[0].stoneType);
          setDefaultSetLength(result.data.data[0].length);
          setDefaultSetWidth(result.data.data[0].width);
          // const upperCaseStoneNo = stoneNo.toUpperCase(); // Corrected method name toUpperCase()
          setNumOfRows(result.data.data[0].pcs);
          // if (upperCaseStoneNo.startsWith("BAG")) {
          //   setNumOfRows(1);
          // } else {
          //   setNumOfRows(result.data.data[0].pcs);
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <div className="card">
        <div className="card-header">
          <span>New Split Stone</span>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="row col-md-12 mt-4">
              <div className="col-md-6">
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <label
                      htmlFor="numOfRows"
                      className="form-label"
                      style={{ marginRight: "20px" }}
                    >
                      Stone No
                    </label>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <input
                      type="text"
                      className="form-control"
                      id="stone_no"
                      placeholder="Type Stone No"
                      value={findStoneNO}
                      onChange={(e) => setFindStoneNo(e.target.value)}
                      onKeyDown={StoneNoSetAndGetData}
                    />
                  </div>
                </div>

                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <label
                      htmlFor="StoneType"
                      className="form-label"
                      style={{ marginRight: "20px" }}
                    >
                      Stone Type
                    </label>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <select
                      className="form-control"
                      name="Stone_Type"
                      value={stoneType}
                      onChange={(e) => setStoneType(e.target.value)}
                    >
                      <option value="0">--Select Stone Type--</option>
                      {options.map((option) => (
                        <option key={option.text} value={option.text}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center mt-4">
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <label
                      htmlFor="numOfRows"
                      className="form-label"
                      style={{ marginRight: "20px" }}
                    >
                      Enter the Stone
                    </label>
                  </div>
                  <div className="col-md-8 col-sm-8 col-lg-8">
                    <input
                      type="number"
                      className="form-control"
                      id="numOfRows"
                      placeholder="Number of Rows"
                      value={numOfRows}
                      onChange={(e) => {
                        setSplitStoneList([]);
                        setNumOfRows(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 d-flex justify-content-center">
                {stoneNoData.length > 0 && (
                  <div className="card" style={{ borderColor: "blue" }}>
                    <h4
                      className="card-title"
                      style={{
                        textAlign: "center",
                        backgroundColor: "#20336b",
                        color: "wheat",
                      }}
                    >
                      {stoneNoData[0].stoneNo}
                    </h4>

                    <div className="card-body table-responsive">
                      <table className="table table-borderd">
                        <thead>
                          <tr>
                            <th>StoneType</th>
                            <th>Pcs</th>
                            <th>Length</th>
                            <th>Width</th>
                            <th>Height</th>
                            <th>Weight</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{stoneNoData[0].stoneType}</td>
                            <td>{stoneNoData[0].pcs}</td>
                            <td>{stoneNoData[0].length}</td>
                            <td>{stoneNoData[0].width}</td>
                            <td>{stoneNoData[0].height}</td>
                            <td>
                              {stoneNoData[0].weight !== null &&
                                stoneNoData[0].weight.toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {numOfRows > 0 && (
              <div className="card-body table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Index</th>
                      <th>Length</th>
                      <th>Width</th>
                      <th>Height</th>
                      <th>
                        Total Weight-{" "}
                        <span>
                          {totalWeight.toFixed(2)}
                        </span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {Array.from({ length: numOfRows }).map((_, rowIndex) => (
                      <tr key={rowIndex} style={{ lineHeight: "10px" }}>
                        <td style={{ textAlign: "center" }}>{rowIndex + 1}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            id={`length-${rowIndex}`}
                            placeholder="Length"
                            value={SplitStoneList[rowIndex]?.length || ""}
                            // value={defaultSetLength}
                            onChange={(e) =>
                              handleRowInputChange(
                                rowIndex,
                                "length",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            id={`width-${rowIndex}`}
                            placeholder="Width"
                            value={SplitStoneList[rowIndex]?.width || ""}
                            // value={defaultSetWidth}
                            onChange={(e) =>
                              handleRowInputChange(
                                rowIndex,
                                "width",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            id={`height-${rowIndex}`}
                            placeholder="Height"
                            value={SplitStoneList[rowIndex]?.height || ""}
                            onChange={(e) =>
                              handleRowInputChange(
                                rowIndex,
                                "height",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            id={`weight-${rowIndex}`}
                            placeholder="Weight"
                            value={SplitStoneList[rowIndex]?.weight || ""}
                            onChange={(e) =>
                              handleRowInputChange(
                                rowIndex,
                                "weight",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <div className="card-footer margin_top10">
              <button
                type="button"
                className="align-items-center btn btn-primary mb-20px"
                onClick={InsertSplitStone}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <span>Split Stone List:</span>
          <div className="d-flex justify-content-center flex-grow-1">
            <Col md={6} lg={3} sm={12} className="my-0.5">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">From</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="start_date"
                  value={dateData.start_date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Col md={6} lg={3} sm={12} className="my-0.5 mx-2">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">To</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="end_date"
                  value={dateData.end_date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Button
              variant="primary"
              onClick={() => getSplitStone()}
            >
              <i className="fa fa-search"></i> Search
            </Button>
          </div>

          <button
            type="submit"
            className="btn btn-primary mb-0px"
            onClick={printSelectedRows}
          >
            Print
          </button>
          <div>
            <Button
              type="button"
              variant="success"
              className="btn btn-primary"
              onClick={exportToExcel}
              style={{ marginLeft: "10px" }}
            >
              <i className="fa fa-file-excel"></i> Export to Excel
            </Button>
          </div>
        </div>
        <div className="card-body">
          <AgGridComponent
            rowData={SplitStoneData}
            columnDefs={columnDefs}
            onSelectionChanged={handleCheckboxSelection}
            paginationPageSize={50}
            height="70vh"
          ></AgGridComponent>
        </div>
      </div>

      {/* Modal Start */}
      <div
        className={`modal fade ${isModalOpen ? "show d-block" : ""}`}
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden={!isModalOpen}
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Split Stone Edit</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-2 col-sm-2 col-lg-2">
                    <label
                      htmlFor="SplitStone_Type_Edit"
                      className="form-label"
                      style={{ marginRight: "20px" }}
                    >
                      Stone Type
                    </label>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <select
                      className="form-control"
                      name="SplitStone_Type_Edit"
                      value={SplitStoneDataForPopup.stoneType}
                      onChange={(e) =>
                        setSplitStoneDataForPopup({
                          ...SplitStoneDataForPopup,
                          SplitStoneType: e.target.value,
                        })
                      }
                    >
                      <option value="0">--Select Stone Type--</option>
                      {options.map((option) => (
                        <option key={option.text} value={option.text}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2 col-sm-2 col-lg-2">
                    <label htmlFor="length_edit" className="form-label">
                      Length :
                    </label>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <input
                      type="text"
                      className="form-control"
                      id="length_edit"
                      placeholder="Enter Length"
                      value={SplitStoneDataForPopup.length}
                      onChange={(e) =>
                        setSplitStoneDataForPopup({
                          ...SplitStoneDataForPopup,
                          length: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-md-2 col-sm-2 col-lg-2">
                    <label htmlFor="width_edit" className="form-label">
                      Width :
                    </label>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <input
                      type="text"
                      className="form-control"
                      id="width_edit"
                      placeholder="Enter Width"
                      value={SplitStoneDataForPopup.width}
                      onChange={(e) =>
                        setSplitStoneDataForPopup({
                          ...SplitStoneDataForPopup,
                          width: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2 col-sm-2 col-lg-2">
                    <label htmlFor="height_edit" className="form-label">
                      Height :
                    </label>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <input
                      type="text"
                      className="form-control"
                      id="height_edit"
                      placeholder="Enter Height"
                      value={SplitStoneDataForPopup.height}
                      onChange={(e) =>
                        setSplitStoneDataForPopup({
                          ...SplitStoneDataForPopup,
                          height: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-2 col-sm-2 col-lg-2">
                    <label htmlFor="weight_edit" className="form-label">
                      Weight :
                    </label>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <input
                      type="text"
                      className="form-control"
                      id="weight_edit"
                      placeholder="Enter Weight"
                      value={SplitStoneDataForPopup.weight}
                      onChange={(e) =>
                        setSplitStoneDataForPopup({
                          ...SplitStoneDataForPopup,
                          weight: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2 col-sm-2 col-lg-2">
                    <label htmlFor="pcs_edit" className="form-label">
                      Pcs :
                    </label>
                  </div>
                  <div className="col-md-1 col-sm-1 col-lg-1">
                    <input
                      type="text"
                      className="form-control"
                      id="pcs_edit"
                      placeholder="Enter PCS"
                      value={SplitStoneDataForPopup.pcs}
                      onChange={(e) =>
                        setSplitStoneDataForPopup({
                          ...SplitStoneDataForPopup,
                          pcs: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer margin_top10">
              <button
                type="button"
                className="btn btn-primary"
                onClick={updateSplitStoneDetails}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Insert Image */}
      {imageModalOpen && (
        <ImageUploadModalComponent
          showModal={imageModalOpen}
          onClose={closeModal} // Pass the function to close the modal
          bNO={modalData.stoneNo}
          sNO={modalData.stoneNo}
          referenceID={modalData.stoneID}
          type="Machine Start"
        />
      )}

      {/* Image Viewer */}
      {isImageViewerOpen && (
        <ImageViewerModalComponent
          showModal={isImageViewerOpen}
          onClose={closeImageViewer}
          referenceID={selectedBatchNo}
          refType="Machine Start"
        />
      )}
    </>
  );
}
