import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";

export default function Machine() {
  const [machineData, setmachineData] = useState([]);
  const [showRemarks, setShowRemarks] = useState(false);

  useEffect(() => {
    getMachine();
  }, []);

  // Fetch all departments
  const getMachine = () => {
    api
      .get("Machine/All")
      .then((result) => {
        setmachineData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Insert & Update Data

  const formik = useFormik({
    initialValues: {
      machine_id: 0,
      machine_no: "",
      grothrate: "",
      status: "Active",
      remarks: "",
    },
    validationSchema: Yup.object({
      machine_no: Yup.string().required("Enter Machine Number"),
      status: Yup.string().required("Select Machine Status"),
    }),
    validateOnChange: true, // Enable validation on change
    validateOnBlur: true, // Enable validation on blur
    onSubmit: (values, { resetForm }) => {
      const data = {
        machineID: values.machine_id,
        machineno: values.machine_no,
        grothrate: values.grothrate,
        machinestatus: values.status,
        remarks: values.remarks,
      };
      const endpoint =
        values.machine_id === 0 ? "Machine/Insert" : "Machine/Edit";

      api[values.machine_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          if (result.data.flag) {
            getMachine();
            const message =
              values.machine_id === 0
                ? "Machine has been inserted"
                : "Machine has been updated";
            toast.success(message);
          } else {
            toast.success(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });
      resetForm();
    },
  });

  // Get data of a single department
  const getSingleMachine = (id) => {
    debugger
    api
      .get(`Machine/ByID?MachineID=${id}`)
      .then((result) => {
        debugger
        const MachineData = result.data.data;
        formik.setFieldValue("machine_no", MachineData.machineNo);
        formik.setFieldValue("status", MachineData.machineStatus.toLowerCase());
        formik.setFieldValue("grothrate", MachineData.grothRate);
        formik.setFieldValue("machine_id", MachineData.machineID);
        formik.setFieldValue("remarks", MachineData.remarks);
      })
      .catch((error) => {
        Swal.fire("Error", error, "error");
      });
  };

  // Delete department
  const deleteMachine = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Machine/Delete?MachineID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1000,
              });
              getMachine();
            }
          })
          .catch((error) => {
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const handleStatusChange = (event) => {
    const selectedValue = event.target.value;
    setShowRemarks(selectedValue === "undermaintenance");
    formik.handleChange(event); // Update the formik value as well
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "Machine No",
      field: "machineNo",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Groth Rate",
      field: "grothRate",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Machine Status",
      field: "machineStatus",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Remarks",
      field: "remarks",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Edit",
      width: 100,
      field: "machineID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleMachine(value)}
        ></ion-icon>
      ),
    },
    {
      headerName: "Delete",
      width: 100,
      field: "machineID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteMachine(value)}
        ></ion-icon>
      ),
    },
  ];

  return (
    <>
      <ToastContainer />
      <div className="card">
        <div className="card-header">
          <span>New Machine</span>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="row col-md-12">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="machine_no" className="form-label">
                  Machine no :
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  id="machine_no"
                  placeholder="Enter Machine No"
                  {...formik.getFieldProps("machine_no")}
                />
                {formik.touched.machine_no && formik.errors.machine_no && (
                  <div className="text-danger">{formik.errors.machine_no}</div>
                )}
              </div>
            </div>
            <div className="row col-md-12 mt-4">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="grothrate" className="form-label">
                  Groth Rate:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  id="grothrate"
                  placeholder="Enter Groth Rate"
                  {...formik.getFieldProps("grothrate")}
                />
                {formik.touched.grothrate && formik.errors.grothrate && (
                  <div className="text-danger">{formik.errors.grothrate}</div>
                )}
              </div>
            </div>
            <div className="row col-md-12 mt-4">
              <div className="col-md-2 col-sm-2 col-lg-2">
                <label htmlFor="status" className="form-label">
                  Machine Status:
                </label>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="input-group">
                  <select
                    id="status"
                    className="form-select"
                    {...formik.getFieldProps("status")}
                    onChange={handleStatusChange}
                  >
                    <option value="">--Select Status--</option>
                    <option value="active">Active</option>
                    <option value="deactive">Deactive</option>
                    <option value="inactive">Inactive</option>
                    <option value="undermaintenance">Under Maintenance </option>
                  </select>
                </div>
                {formik.touched.status && formik.errors.status && (
                  <div className="text-danger">{formik.errors.status}</div>
                )}
              </div>
            </div>
            {showRemarks && (
              <div className="row col-md-12 mt-4">
                <div className="col-md-2 col-sm-2 col-lg-2">
                  <label htmlFor="Remarks" className="form-label">
                    Remarks:
                  </label>
                </div>
                <div className="col-md-4 col-sm-4 col-lg-4">
                  <input
                    type="text"
                    className="form-control"
                    id="remarks"
                    placeholder="Enter Remarks For UnderMaintenance"
                    {...formik.getFieldProps("remarks")}
                  />
                  {formik.touched.remarks && formik.errors.remarks && (
                    <div className="text-danger">{formik.errors.remarks}</div>
                  )}
                </div>
              </div>
            )}
            <div className="card-footer margin_top10">
              <button type="submit" className="btn btn-primary mb-20px">
                {formik.values.machine_no > 0 ? "Update" : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <span>Machine List</span>
        </div>
        <div className="card-body">
          <AgGridComponent
            rowData={machineData}
            columnDefs={columnDefs}
            paginationPageSize={100}
            height="70vh"
          ></AgGridComponent>
        </div>
      </div>
    </>
  );
}
