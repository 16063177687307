import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import "./MachineDashboard.css";
import { addDecimalPlaces } from "../../Utils/commonfunctions";
import { Modal, Table, Card, Row, Col } from "react-bootstrap";
import UserSetting from "../../UserSettings.json";
import "lightbox.js-react/dist/index.css";
import Spinner from "react-bootstrap/Spinner";
import PhotoGallery from "../../PhotoGallery/PhotoGallery";
import Swal from "sweetalert2";

export default function MachineDashboard() {
  const [machineDashboardData, setMachineDashboardData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stoneDataForPopup, setStoneDataForPopup] = useState([]);
  const [batchDataForPopup, setBatchDataForPopup] = useState([]);
  const [showMachineStartTime, setShowMachineStartTime] = useState([]);
  const [
    machineDashboardDataWhereMachineOff,
    setMachineDashboardDataWhereMachineOff,
  ] = useState([]);
  const [
    machineDashboardDataWhereMachineUM,
    setMachineDashboardDataWhereMachineUM,
  ] = useState([]);
  const [showOffMachine, setShowOffMachine] = useState(true);
  const [showOnMachine, setShowOnMachine] = useState(true);
  const [showUMMachine, setShowUMMachine] = useState(true);

  const [totalPcs, setTotalPCS] = useState(0);
  const [totalHeight, setTotalHeight] = useState(0);
  const [totalWeight, setTotalWight] = useState(0);

  //Image Viewer
  const [imageViewer, setImageViewer] = useState([]);
  const [imageShow, setImageShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getMachineDashboard();
    getMachineDashboardWhereMachineOff();
    getMachineDashboardWhereMachineUM();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      calculateTimeDifference();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (imageViewer && imageViewer.length > 0) {
      setImageShow(true);
    } else {
      setImageShow(false);
    }
  }, [imageViewer]);

  const getMachineDashboard = () => {
    setIsLoading(true); // Show loader
    api
      .get("IssueToMachine/MachineDataForMachineDashboard")
      .then((result) => {
        var Record = result.data.data;
        setMachineDashboardData(result.data.data);
        calculateTotals(Record);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Hide loader
      });
  };
  const calculateTotals = (data) => {
    let totalPcs = 0;
    let totalHeightSum = 0;
    let totalWeightSum = 0;

    data.forEach((item) => {
      totalPcs += item.totalPcs;
      totalHeightSum += item.totalPcs * item.avgtotalHeight;
      totalWeightSum += item.totalWeight;
    });

    setTotalPCS(totalPcs);
    setTotalHeight((totalHeightSum / totalPcs).toFixed(2)); // calculating average height
    setTotalWight(totalWeightSum.toFixed(2));
  };

  const getMachineDashboardWhereMachineOff = () => {
    api
      .get("IssueToMachine/MachineDataForMachineDashboardwhereMachineOff")
      .then((result) => {
        setMachineDashboardDataWhereMachineOff(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMachineDashboardWhereMachineUM = () => {
    api
      .get("IssueToMachine/MachineDataForMachineDashboardwhereMachineUM")
      .then((result) => {
        setMachineDashboardDataWhereMachineUM(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const calculateTimeDifference = () => {
    setMachineDashboardData((prevData) =>
      prevData.map((item) => {
        const currentTime = new Date();
        const machineTimeDate = new Date(item.machineStart);
        const timeDifference = currentTime - machineTimeDate;

        const totalSeconds = Math.floor(timeDifference / 1000);
        const days = Math.floor(totalSeconds / (24 * 60 * 60));
        const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / 3600)
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((totalSeconds % 3600) / 60)
          .toString()
          .padStart(2, "0");
        const seconds = (totalSeconds % 60).toString().padStart(2, "0");
        const totalHours = Math.floor(totalSeconds / 3600);

        let currentHeight = 0.0;

        if (item.grothRate > 0) {
          currentHeight = item.avgtotalHeight + totalHours * item.grothRate;
        }

        return {
          ...item,
          totalDays: days,
          totalHours: totalHours,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
          currentHeight: addDecimalPlaces(currentHeight, 2),
        };
      })
    );
  };

  const handleStartClick = (no) => {
    setIsModalOpen(true);
    setImageViewer([]); // Clear the imageList before making the API call

    api
      .get(`IssueToMachine/MachineDashboardDataByBatchNoforPopup?BatchNo=${no}`)
      .then((result) => {
        setIsModalOpen(true);
        setStoneDataForPopup(result.data.data.stoneList);
        setBatchDataForPopup(result.data.data.batchList);
        const newImageList = result.data.data.imageList;
        setImageViewer(newImageList);
        console.log(newImageList);
        setShowMachineStartTime(result.data.data.batchList[0].machineTime);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseMode = () => {
    setIsModalOpen(false);
    setStoneDataForPopup([]);
    setBatchDataForPopup([]);
    setImageViewer([]); // Clear the imageList when closing the modal
  };


  const deleteImage = (id, batchNo) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be to Delete this Image!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {

        api
          .patch(`Machine/DeleteImageOfMachine?ImageForAllID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              handleStartClick(batchNo);
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  }

  return (
    <>
      {isLoading && (
        <>
          <div className="overlay"></div>
          <div className="overlay-content text-center">
            <Spinner animation="" variant="primary">
              <span class="loader"></span>
            </Spinner>
          </div>
        </>
      )}
      <ToastContainer />
      <div className="card">
        <div
          className="row align-items-center"
          style={{ marginLeft: "0px", marginRight: "0px" }}
        >
          <div
            className="col-md-2 col-sm-2 col-lg-2"
            onClick={() => {
              setShowOffMachine(true);
              setShowOnMachine(true);
            }}
          >
            <div className="machineSummary allMachineSummary">
              <h2>
                All Machine:{" "}
                {machineDashboardData.length +
                  machineDashboardDataWhereMachineOff.length}
              </h2>
            </div>
          </div>
          <div
            className="col-md-2 col-sm-2 col-lg-2"
            onClick={() => {
              setShowOffMachine(false);
              setShowOnMachine(true);
              setShowUMMachine(false);
            }}
          >
            <div className="machineSummary allOnMachineSummary">
              <h2>On: {machineDashboardData.length}</h2>
            </div>
          </div>
          <div
            className="col-md-2 col-sm-2 col-lg-2"
            onClick={() => {
              setShowOffMachine(true);
              setShowOnMachine(false);
              setShowUMMachine(false);
            }}
          >
            <div className="machineSummary allOffMachineSummary">
              <h2>Off: {machineDashboardDataWhereMachineOff.length}</h2>
            </div>
          </div>
          <div
            className="col-md-2 col-sm-2 col-lg-2"
            onClick={() => {
              setShowUMMachine(true);
              setShowOnMachine(false);
              setShowOffMachine(false);
            }}
          >
            <div className="machineSummary allUMMachineSummary">
              <h2>UM: {machineDashboardDataWhereMachineUM.length}</h2>
            </div>
          </div>
          <div className="col-md-2 col-sm-2 col-lg-2 ">
            <div className="machineSummaryForStoneCount">
              <h2>Pcs: {totalPcs}</h2>
            </div>
          </div>
          <div className="col-md-2 col-sm-2 col-lg-2 ">
            <div className="machineSummaryForStoneCount">
              <h2>H: {totalHeight}</h2>
            </div>
          </div>
          <div className="col-md-2 col-sm-2 col-lg-2 ">
            <div className="machineSummaryForStoneCount">
              <h2>W: {totalWeight}</h2>
            </div>
          </div>
        </div>
      </div>
      {showOnMachine && (
        <div className="card">
          <div className="card-header">
            <span>Machine On</span>
          </div>
          <div className="card-body">
            <div className="row">
              {machineDashboardData.map((item) => (
                <div
                  className="col-md-3 col-sm-3 col-lg-3"
                  key={item.machineNo}
                  onClick={() => handleStartClick(item.batchNo)}
                >
                  <div className="machineDiv">
                    <table>
                      <tbody>
                        <tr className="firstColumn">
                          <td className="width40">{item.machineNo}</td>
                          <td className="width20">{item.batchNo}</td>
                          <td className="width40">
                            <i className="far fa-gem"></i> {item.totalPcs}
                          </td>
                        </tr>
                        <tr className="time">
                          <td className="width40">
                            {item.totalDays} Day, {item.hours} Hr
                          </td>
                          <td className="width20">
                            <i className="far fa-clock fa-spin"></i>
                          </td>
                          <td className="width40">
                            {item.totalHours}:{item.minutes}:{item.seconds}
                          </td>
                        </tr>
                        <tr className="LastColumn">
                          <td>Ht: {item.avgtotalHeight}</td>
                          <td>
                            <i className="fas fa-balance-scale-right"></i>
                          </td>
                          <td>Ht: {item.currentHeight}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {showOffMachine && (
        <div className="card">
          <div className="card-header">
            <span>Machine Off</span>
          </div>
          <div className="card-body">
            <div className="row">
              {machineDashboardDataWhereMachineOff.map((item) => (
                <div
                  className="col-md-3 col-sm-3 col-lg-3"
                  key={item.machineNo}
                >
                  <div className="machineDivOff">{item.machineNo}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {showUMMachine && (
        <div className="card">
          <div className="card-header">
            <span>Machine UndetMaintainance</span>
          </div>
          <div className="card-body">
            <div className="row">
              {machineDashboardDataWhereMachineUM.map((item) => (
                <div
                  className="col-md-3 col-sm-3 col-lg-3"
                  key={item.machineNo}
                >

                  <div className="machineDivUM"><div> {item.machineNo}</div>
                    <div className="machinedivUMText">{item.remarks}</div></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <Modal show={isModalOpen} onHide={handleCloseMode} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Data</Modal.Title>
          <div className="text-center w-100">
            <div style={{ color: "#263369" }}>
              <strong className="machineTitle">
                Machine Start: {showMachineStartTime}
              </strong>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Header>
              {" "}
              <span>Batch AvgList</span>{" "}
            </Card.Header>
            <Card.Body>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Batch No</th>
                    <th>Total Pcs</th>
                    <th>Avgtotal Length</th>
                    <th>Avgtotal Width</th>
                    <th>Avgtotal Height</th>
                    <th>Total Weight</th>
                  </tr>
                </thead>
                <tbody>
                  {batchDataForPopup &&
                    batchDataForPopup.length > 0 &&
                    batchDataForPopup.map((item) => (
                      <tr key={item.batchNo}>
                        <td>{item.batchNo}</td>
                        <td>{item.totalPcs}</td>
                        <td>{item.avgtotalLength}</td>
                        <td>{item.avgtotalWidth}</td>
                        <td>{item.avgtotalHeight}</td>
                        <td>{item.totalWeight}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              {" "}
              <span>Stone List</span>{" "}
            </Card.Header>
            <Card.Body>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Stone No</th>
                    <th>BatchNo</th>
                    <th>Pcs</th>
                    <th>Length</th>
                    <th>Width</th>
                    <th>Height</th>
                    <th>Weight</th>
                  </tr>
                </thead>
                <tbody>
                  {stoneDataForPopup &&
                    stoneDataForPopup.length > 0 &&
                    stoneDataForPopup.map((item) => (
                      <tr key={item.stoneNo}>
                        <td>{item.stoneNo}</td>
                        <td>{item.batchNo}</td>
                        <td>{item.pcs}</td>
                        <td>{item.length}</td>
                        <td>{item.width}</td>
                        <td>{item.height}</td>
                        <td>{item.weight}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          {imageShow && (
            <Card>
              <Card.Header>
                {" "}
                <span>Images</span>{" "}
              </Card.Header>
              <Card.Body>
                <PhotoGallery
                  className="row"
                  fullScreen={true}
                  // className="container grid grid-cols-3 gap-2 mx-auto"
                  showThumbnails={true}
                >
                  <Row>
                    {imageViewer.map(
                      (item, index) =>
                        item[0].imagePath !== null && (
                          <Col
                            sm={3}
                            md={3}
                            lg={3}
                            className="machineDeshbord-Image-Popup"
                          >
                            <div className="machineDeshbord-image-container">
                              <img
                                className="rounded"
                                src={UserSetting.imgURL + item[0].imagePath}
                                alt=""
                              />

                              <ion-icon
                                name="trash-outline"
                                class="iconsFonts deleteIcon machineDeshbord-delete-button"
                                onClick={() => deleteImage(item[0].imagesForAllID, item[0].batchNo)}
                              ></ion-icon>
                            </div>
                          </Col>
                        )
                    )}
                  </Row>
                </PhotoGallery>
              </Card.Body>
            </Card>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
