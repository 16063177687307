import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Card, Row, Col, Form, Button, Modal, InputGroup } from "react-bootstrap";
import Select from "react-select";
import api from "../../Utils/api";
import ClarityMasterDD from "../ClarityMaster/ClarityMasterDD";
import ColorMasterDD from "../ColorMaster/ColorMasterDD";
import "react-toastify/dist/ReactToastify.css";
import { ISOToDateTime } from "../../Components/Common/Common";
import _ from "lodash";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import ReactDOMServer from "react-dom/server";
import PrintSingleStoneDataEntry from "./PrintSingleStoneDataEntry";
import Loader from "../../Components/Common/Loader";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { GetPre7DayDate, getCurrentDate } from "../../Utils/commonfunctions";

const sumAggFunc = (params) => {
  const values = params.values;
  let sum = 0;

  values.forEach((value) => {
    sum += value;
  });

  return sum;
};

const emtSearchFormData = {
  start_date: GetPre7DayDate(),
  end_date: getCurrentDate(),
};

const pinnedBottomRowData = [
  {
    receivedDate: null,
    stoneNo: "",
    stoneType: "TOTAL",
    pcs: 0,
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
    rW_Weight: 0,
    r_Weight: 0,
    eM_Weight: 0,
    oV_Weight: 0,
    pN_Weight: 0,
    cU_Weight: 0,
  },
];

export default function SingleStoneDataEntry() {
  const [stoneData, setStoneData] = useState([]);
  const [stoneNo, setStoneNo] = useState("");
  const [colorMasterName, setColorMasterName] = useState([]);
  const [selectedColorMaster, setSelectedColorMaster] = useState(null);
  const [clarityMasterName, setClarityMasterName] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showColorPopup, setShowColorPopup] = useState(false);
  const [selectedClarityMaster, setSelectedClarityMaster] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [stoneList, setStoneList] = useState([]);
  const [formData, setFormData] = useState({
    stoneID: 0,
    colorMasterName: "",
    rwWeight: "",
    rWeight: "",
    emWeight: "",
    ovWeight: "",
    pnWeight: "",
    cuWeight: "",
    clarityMasterName: "",
  });
  const [dateData, setDateData] = useState(emtSearchFormData);


  useEffect(() => {
    fetchColorMasterData();
    fetchClarityMasterData();
    getStockList();
    calculateSums();
  }, []);

  const fetchColorMasterData = () => {
    return api
      .get("ColorMaster/All")
      .then((result) => {
        setColorMasterName(result.data.data); // Assuming setColorMasterName is defined elsewhere
        return result.data.data; // Return data for chaining
      })
      .catch((error) => {
        console.error("Error fetching color master data:", error);
        toast.error("An error occurred while fetching color master data.");
      });
  };

  const fetchClarityMasterData = () => {
    return api
      .get("ClarityMaster/All")
      .then((result) => {
        setClarityMasterName(result.data.data);
        return result.data.data;
      })
      .catch((error) => {
        console.error("Error fetching party master data:", error);
        toast.error("An error occurred while fetching party master data.");
      });
  };

  const InsertSingleStone = () => {
    setIsLoading(true);
    if (!stoneData.stoneID) {
      toast.error("Select ASI!");
      setIsLoading(false);
      return;
    }
    const data = {
      stoneID: formData.stoneID,
      colorMasterName: selectedColorMaster?.label,
      RW_Weight: formData.rwWeight,
      R_Weight: formData.rWeight,
      EM_Weight: formData.emWeight,
      OV_Weight: formData.ovWeight,
      PN_Weight: formData.pnWeight,
      CU_Weight: formData.cuWeight,
      ClarityMsaterName: selectedClarityMaster?.label,
    };

    api
      .patch(`SingleStoneDataEntry/UpdateSingleStoneDataEntry`, data)
      .then((result) => {
        toast.success(result.data.message);
        // Clear form data
        setFormData({
          colorMasterName: "",
          rwWeight: "",
          rWeight: "",
          emWeight: "",
          ovWeight: "",
          pnWeight: "",
          cuWeight: "",
          clarityMasterName: "",
        });
        PrintSingleStoneData(formData.stoneID);
        getStockList();
        setSelectedColorMaster(null);
        setSelectedClarityMaster(null);
        setStoneNo("");
        setStoneData([]);
      })
      .catch((error) => {
        toast.error("An error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      fetchStoneData();
    }
  };

  const handleInputDateChange = (event) => {
    const { name, value } = event.target;
    setDateData({ ...dateData, [name]: value });
  };


  const fetchStoneData = () => {
    debugger;
    api
      .get(`SingleStoneDataEntry/FindStone?StoneNo=${stoneNo}`)
      .then((result) => {
        // setStoneNo("");
        let data = result.data;
        if (data.flag === false) {
          toast.error(data.message);
          return;
        } else {
          setStoneData(data.data);
          const selectedColor = colorMasterName.find(
            (option) => option.colorMasterName === data.data.colorMasterName
          );
          const selectedClarity = clarityMasterName.find(
            (option) => option.clarityMasterName === data.data.clarityMsaterName
          );

          setSelectedColorMaster(
            selectedColor
              ? {
                value: selectedColor.colorMastedID,
                label: selectedColor.colorMasterName,
              }
              : null
          );
          setSelectedClarityMaster(
            selectedClarity
              ? {
                value: selectedClarity.clarityMasterID,
                label: selectedClarity.clarityMasterName,
              }
              : null
          );
          setFormData({
            stoneID: data.data.stoneID,
            colorMasterName: data.data.colorMasterName,
            rwWeight: data.data.weight,
            rWeight: data.data.r_Weight,
            emWeight: data.data.eM_Weight,
            ovWeight: data.data.oV_Weight,
            pnWeight: data.data.pN_Weight,
            cuWeight: data.data.cU_Weight,
            clarityMasterName: data.data.clarityMsaterName,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred while fetching stone details.");
      });
  };

  const handleColorMasterChange = (option) => {
    setSelectedColorMaster(option);
  };

  const handleClarityMasterChange = (option) => {
    setSelectedClarityMaster(option);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const selectDDClarityMasterID = (clarityMasterID) => {
    fetchClarityMasterData()
      .then((data) => {
        const selectedClarityMaster = data.find(
          (f) => f.clarityMasterID === clarityMasterID
        );
        if (selectedClarityMaster) {
          var option = {
            value: clarityMasterID,
            label: selectedClarityMaster.clarityMasterName,
          };
          handlepartymasterChange(option);
          setShowPopup(false);
        } else {
          console.error("Party master not found for ID:");
          // Optionally, handle the case where party master is not found
        }
      })
      .catch((error) => {
        console.error("Error fetching party master data:", error);
        // Optionally, handle the error
      });
  };

  const handlepartymasterChange = (option) => {
    setSelectedClarityMaster(option);
  };

  const selectDDcolorMasterID = (colorMasterID) => {
    // Use  only if needed for development
    fetchColorMasterData()
      .then((data) => {
        const selectedColorMaster = data.find(
          (f) => f.colorMastedID === colorMasterID
        );
        if (selectedColorMaster) {
          const option = {
            value: colorMasterID,
            label: selectedColorMaster.colorMasterName, // Ensure the property name is correct
          };
          handlepColormasterChange(option);
          setShowColorPopup(false);
        } else {
          console.error("Color master not found for ID:");
        }
      })
      .catch((error) => {
        console.error("Error fetching color master data:", error);
      });
  };
  const handlepColormasterChange = (option) => {
    setSelectedColorMaster(option);
  };

  const getStockList = () => {
    setIsLoading(true);
    api
      .get(`Stone/CurrentStockOfBagAsPrUserData?START_DATE=${dateData.start_date}&END_DATE=${dateData.end_date}`)
      .then((result) => {
        setStoneList(result.data.data);
        // setTotalStock({
        //   totalPcs: result.data.data.totalStoneSummary.pcs,
        //   totalLength: result.data.data.totalStoneSummary.length,
        //   totalWidth: result.data.data.totalStoneSummary.width,
        //   totalHeight: result.data.data.totalStoneSummary.height,
        //   totalWeight: result.data.data.totalStoneSummary.weight,
        // });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Hide loader
      });
  };

  useEffect(() => {
    updatePinnedBottomRowData();
  }, [stoneList]);

  const calculateSums = (event) => {
    let filteredData;
    if (event === undefined) {
      filteredData = stoneList; // Assuming StoneData is defined somewhere
    } else {
      filteredData = event.api.getModel().rowsToDisplay.map((row) => row.data);
    }

    const filteredSumPcs = _.sumBy(filteredData, "pcs");
    const totalLength = _.sumBy(filteredData, (item) => item.pcs * item.length);
    const totalWidth = _.sumBy(filteredData, (item) => item.pcs * item.width);
    const totalHeight = _.sumBy(filteredData, (item) => item.pcs * item.height);

    const filteredSumLength = parseFloat(
      (totalLength / filteredSumPcs).toFixed(2)
    );
    const filteredSumWidth = parseFloat(
      (totalWidth / filteredSumPcs).toFixed(2)
    );
    const filteredSumHeight = parseFloat(
      (totalHeight / filteredSumPcs).toFixed(2)
    );
    const filteredSumWeight = parseFloat(
      _.sumBy(filteredData, "weight").toFixed(2)
    );

    const filteredWeights = filteredData.map((item) => ({
      rW_Weight: item.rW_Weight || 0,
      r_Weight: item.r_Weight || 0,
      eM_Weight: item.eM_Weight || 0,
      oV_Weight: item.oV_Weight || 0,
      pN_Weight: item.pN_Weight || 0,
      cU_Weight: item.cU_Weight || 0,
    }));

    const filteredSumRW_Weight = parseFloat(
      _.sumBy(filteredWeights, "rW_Weight").toFixed(2)
    );
    const filteredSumR_Weight = parseFloat(
      _.sumBy(filteredWeights, "r_Weight").toFixed(2)
    );
    const filteredSumEM_Weight = parseFloat(
      _.sumBy(filteredWeights, "eM_Weight").toFixed(2)
    );
    const filteredSumOV_Weight = parseFloat(
      _.sumBy(filteredWeights, "oV_Weight").toFixed(2)
    );
    const filteredSumPN_Weight = parseFloat(
      _.sumBy(filteredWeights, "pN_Weight").toFixed(2)
    );
    const filteredSumCU_Weight = parseFloat(
      _.sumBy(filteredWeights, "cU_Weight").toFixed(2)
    );

    // Return calculated values
    return {
      footerTotalPcs: filteredSumPcs,
      footerTotalLength: isNaN(filteredSumLength) ? 0 : filteredSumLength,
      footerTotalWidth: isNaN(filteredSumWidth) ? 0 : filteredSumWidth,
      footerTotalHeight: isNaN(filteredSumHeight) ? 0 : filteredSumHeight,
      footerTotalWeight: filteredSumWeight,
      footerTotalRW_Weight: isNaN(filteredSumRW_Weight)
        ? 0
        : filteredSumRW_Weight,
      footerTotalR_Weight: isNaN(filteredSumR_Weight) ? 0 : filteredSumR_Weight,
      footerTotalEM_Weight: isNaN(filteredSumEM_Weight)
        ? 0
        : filteredSumEM_Weight,
      footerTotalOV_Weight: isNaN(filteredSumOV_Weight)
        ? 0
        : filteredSumOV_Weight,
      footerTotalPN_Weight: isNaN(filteredSumPN_Weight)
        ? 0
        : filteredSumPN_Weight,
      footerTotalCU_Weight: isNaN(filteredSumCU_Weight)
        ? 0
        : filteredSumCU_Weight,
    };
  };

  const updatePinnedBottomRowData = (event) => {
    const sums = calculateSums(event);
    pinnedBottomRowData[0].receivedDate = null;
    pinnedBottomRowData[0].stoneNo = "";
    pinnedBottomRowData[0].stoneType = "TOTAL";
    pinnedBottomRowData[0].length = sums.footerTotalLength;
    pinnedBottomRowData[0].width = sums.footerTotalWidth;
    pinnedBottomRowData[0].height = sums.footerTotalHeight;
    pinnedBottomRowData[0].weight = sums.footerTotalWeight;
    pinnedBottomRowData[0].pcs = sums.footerTotalPcs;
    pinnedBottomRowData[0].rW_Weight = sums.footerTotalRW_Weight;
    pinnedBottomRowData[0].r_Weight = sums.footerTotalR_Weight;
    pinnedBottomRowData[0].eM_Weight = sums.footerTotalEM_Weight;
    pinnedBottomRowData[0].oV_Weight = sums.footerTotalOV_Weight;
    pinnedBottomRowData[0].pN_Weight = sums.footerTotalPN_Weight;
    pinnedBottomRowData[0].cU_Weight = sums.footerTotalCU_Weight;
  };

  // Example usage
  const onFilterChanged = (event) => {
    updatePinnedBottomRowData(event);
    if (event) {
      event.api.refreshCells({ force: true, suppressFlash: true });
    }
  };

  // const PrintSingleStoneData = (stoneID) => {
  //   // Create an iframe element
  //   const printWindow = document.createElement("iframe");
  //   printWindow.style.display = "none";

  //   // Make API call to fetch data
  //   api
  //   .get(`SingleStoneDataEntry/SingleStoneDataEntryStiker?StoneID=${stoneID}`)
  //     .then(({ data }) => {
  //       // Render component with fetched data
  //       const printComponent = <PrintSingleStoneDataEntry stoneDetail={data.data[0]} />;
  //       console.log(data.data)
  //       const printContent =
  //         ReactDOMServer.renderToStaticMarkup(printComponent);

  //       // Append the iframe to the document body
  //       document.body.appendChild(printWindow);

  //       // Access iframe document
  //       const iframeDocument =
  //         printWindow.contentDocument || printWindow.contentWindow.document;

  //       // Write the content to the iframe document
  //       iframeDocument.write(`
  //               <html>
  //                   <head>
  //                       <title>Print</title>
  //                       <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
  //                       <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  //                   </head>
  //                   <body>
  //                       ${printContent}
  //                   </body>
  //               </html>`);

  //       // Trigger printing
  //       setTimeout(() => {
  //         printWindow.contentWindow.print();
  //       }, 400);
  //     })
  //     .catch((error) => {
  //       console.error("API error:", error);
  //     });
  // };
  const PrintSingleStoneData = (stoneID) => {
    // Make API call to fetch data
    api
      .get(`SingleStoneDataEntry/SingleStoneDataEntryStiker?StoneID=${stoneID}`)
      .then(({ data }) => {
        // Render component with fetched data
        const printComponent = (
          <PrintSingleStoneDataEntry stoneDetail={data.data[0]} />
        );
        console.log(data.data);
        const printContent =
          ReactDOMServer.renderToStaticMarkup(printComponent);

        // Open a new window
        const printWindow = window.open("", "_blank");

        // Write the content to the new window document
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print</title>
                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
                </head>
                <body>
                    ${printContent}
                </body>
            </html>`);

        // Wait for the content to be fully loaded before triggering print
        printWindow.document.close();
        printWindow.onload = () => {
          printWindow.print();
          printWindow.onafterprint = () => {
            printWindow.close();
          };
        };
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  };

  const exportToExcel = async () => {
    setIsLoading(true);

    try {
      const fileName = "SingleStoneDataEntry.xlsx";

      // Format the data to be exported
      const formattedData = stoneList.map((item) => {
        const date = new Date(item.receivedDate);
        const formattedDate = date.toLocaleDateString("en-GB"); // Formats the date as dd/mm/yyyy
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedTime = `${hours % 12 || 12
          }:${minutes}:${seconds} ${ampm}`;
        const formattedDateTime = `${formattedDate} ${formattedTime}`;

        return {
          Date: formattedDateTime,
          StoneNo: item.stoneNo,
          StoneType: item.stoneType,
          Pcs: item.pcs,
          Length: item.length,
          Width: item.width,
          Height: item.height,
          Weight: item.weight,
          Rough: item.rW_Weight,
          Round: item.r_Weight,
          EM: item.eM_Weight,
          OV: item.oV_Weight,
          PN: item.pN_Weight,
          CU: item.cU_Weight,
        };
      });

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Issue Machine List");

      // Set up the columns
      worksheet.columns = [
        { header: "Date", key: "Date", width: 20 },
        { header: "Stone No", key: "StoneNo", width: 20 },
        { header: "Stone Type", key: "StoneType", width: 20 },
        { header: "PCS", key: "Pcs", width: 10 },
        { header: "Length", key: "Length", width: 15 },
        { header: "Width", key: "Width", width: 15 },
        { header: "Height", key: "Height", width: 15 },
        { header: "Weight", key: "Weight", width: 15 },
        { header: "Rough", key: "Rough", width: 15 },
        { header: "Round", key: "Round", width: 15 },
        { header: "EM", key: "EM", width: 15 },
        { header: "OV", key: "OV", width: 15 },
        { header: "PN", key: "PN", width: 15 },
        { header: "CU", key: "CU", width: 15 },
      ];

      // Add the rows to the worksheet
      worksheet.addRows(formattedData);

      // Style the header row
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF4F81BD" },
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
      });

      // Set date format for the 'Date' column
      worksheet.getColumn("Date").numFmt = "MM/DD/YYYY hh:mm AM/PM";

      // Adjust column widths based on content length
      worksheet.columns.forEach((column) => {
        let maxLength = column.header.length;
        formattedData.forEach((row) => {
          const cellValue = row[column.key];
          if (cellValue) {
            maxLength = Math.max(maxLength, cellValue.toString().length);
          }
        });
        column.width = Math.max(maxLength + 2, column.width); // Add padding
      });

      // Export the Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, fileName);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      // Handle any error here, such as showing an error message to the user
    } finally {
      setIsLoading(false);
    }
  };

  const columnDefs = [
    {
      headerName: "Date",
      field: "receivedDate",
      flex: 1,
      minWidth: 200,
      cellStyle: { textAlign: "left" },
      valueFormatter: (params) => {
        if (params.value) {
          return ISOToDateTime(params.value);
        }
        return "";
      },
    },
    {
      headerName: "No",
      field: "stoneNo",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 100,
    },
    {
      headerName: "Type",
      field: "stoneType",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 100,
    },
    {
      headerName: "Pcs",
      field: "pcs",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      aggFunc: sumAggFunc,
      minWidth: 100, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Length",
      field: "length",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      aggFunc: sumAggFunc,
      minWidth: 100, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Width",
      field: "width",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      aggFunc: sumAggFunc,
      minWidth: 100, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Height",
      field: "height",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      aggFunc: sumAggFunc,
      minWidth: 100, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Weight",
      field: "weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      aggFunc: sumAggFunc,
      minWidth: 100, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Rough",
      field: "rW_Weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      aggFunc: sumAggFunc,
      minWidth: 100, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Round",
      field: "r_Weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      aggFunc: sumAggFunc,
      minWidth: 100, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "EM",
      field: "eM_Weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      aggFunc: sumAggFunc,
      minWidth: 100, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "OV",
      field: "oV_Weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      aggFunc: sumAggFunc,
      minWidth: 100, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "PN",
      field: "pN_Weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      aggFunc: sumAggFunc,
      minWidth: 100, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "CU",
      field: "cU_Weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => {
        // Check if value is zero
        if (value === 0 || value == null) {
          return "0"; // Display "0" if value is zero
        } else {
          return value.toFixed(2);
        }
      },
      aggFunc: sumAggFunc,
      minWidth: 100, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Print List",
      width: 100,
      field: "stoneID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      pinned: "right",
      cellRenderer: ({ value }) =>
        value && (
          <ion-icon
            name="print-outline"
            class="iconsFonts"
            onClick={() => PrintSingleStoneData(value)}
          ></ion-icon>
        ),
    },
  ];

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Card>
        <Card.Header>
          <span>Single Stone Data Entry</span>
          {stoneData.stoneNo && (
            <>
              ({" "}
              <strong style={{ marginRight: "10px" }}>
                ASINo : {stoneData.stoneNo} ,
              </strong>
              <strong>Weight : {stoneData.weight}</strong>)
            </>
          )}
        </Card.Header>
        <Card.Body>
          <Form noValidate>
            <Row>
              <Col md={4}>
                {" "}
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    Find Stone :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Group controlId="stoneNoInput">
                      <Form.Control
                        type="text"
                        name="stoneNo"
                        placeholder="Find Stone"
                        value={stoneNo}
                        onChange={(e) => setStoneNo(e.target.value)}
                        onKeyDown={handleKeyPress}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter the stone number.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    Color:
                  </Form.Label>
                  <Col sm="9">
                    <Form.Group controlId="colorMaster">
                      <div className="d-flex justify-content-between">
                        <Select
                          value={selectedColorMaster}
                          onChange={handleColorMasterChange}
                          options={colorMasterName.map((option) => ({
                            value: option.colorMasterID,
                            label: option.colorMasterName,
                          }))}
                          placeholder="--Select Color Master--"
                        />
                        <div className="selectEndIcon input-group-text">
                          <i
                            className="fa fa-plus plusIcon"
                            onClick={() => setShowColorPopup(true)}
                          ></i>
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    Clarity:
                  </Form.Label>
                  <Col sm="9">
                    <Form.Group controlId="clarityMasterName">
                      <div className="mb-3 d-flex justify-content-between">
                        <Select
                          className="w-full"
                          value={selectedClarityMaster}
                          onChange={handleClarityMasterChange}
                          options={clarityMasterName.map((option) => ({
                            value: option.colorMasterID,
                            label: option.clarityMasterName,
                          }))}
                          placeholder="--Select Clarity Master--"
                        />
                        <div className="selectEndIcon input-group-text">
                          <i
                            className="fa fa-plus plusIcon"
                            onClick={() => setShowPopup(true)}
                          ></i>
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={4}>
                {" "}
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    Rough:
                  </Form.Label>
                  <Col sm="9">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="number"
                        name="rwWeight"
                        placeholder="Enter Rough"
                        value={formData.rwWeight}
                        onChange={handleInputChange}
                        required
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter The Value Rough Weight
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    Round:
                  </Form.Label>
                  <Col sm="9">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="number"
                        name="rWeight"
                        placeholder="Enter Round"
                        value={formData.rWeight}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter The Value Round Weight
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    EM:
                  </Form.Label>
                  <Col sm="9">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="number"
                        name="emWeight"
                        placeholder="Enter EM_Weight"
                        value={formData.emWeight}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter The Value EM_Weight
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    OV:
                  </Form.Label>
                  <Col sm="9">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="number"
                        name="ovWeight"
                        placeholder="Enter OV_Weight"
                        value={formData.ovWeight}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter The Value PartyName
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={4}>
                {" "}
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    PN:
                  </Form.Label>
                  <Col sm="9">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="number"
                        name="pnWeight"
                        placeholder="Enter PN_Weight"
                        value={formData.pnWeight}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter The Value PartyName
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    CU:
                  </Form.Label>
                  <Col sm="9">
                    <Form.Group controlId="stoneTypeSelect">
                      <Form.Control
                        type="number"
                        name="cuWeight"
                        placeholder="Enter CU_Weight"
                        value={formData.cuWeight}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter The Value PartyName
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>

          {/* <Col md={6} className="justify-content-center">
              {stoneData.length > 0 && (
                <div
                  className="card  d-flex "
                  style={{
                    borderColor: "gray",
                    width: "400px",
                    marginLeft: "281px",
                  }}
                >
                  <Table striped hover table-responsive>
                    <thead
                      style={{ backgroundColor: "#20336b", color: "white" }}
                    >
                      <tr>
                        <th>Bag No</th>
                        <th>Weight</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stoneData &&
                        stoneData.map((stone, index) => (
                          <tr key={index}>
                            <td>{stone.stoneNo}</td>
                            <td>{stone.weight}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </Col> */}
          <div className="card-footer">
            <Button
              type="button"
              variant="primary"
              className="mb-30"
              onClick={InsertSingleStone}
            >
              Save
            </Button>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <span>Generated Bags:</span>
          <div className="d-flex justify-content-center flex-grow-1">
            <Col md={6} lg={3} sm={12} className="my-0.5">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">From</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="start_date"
                  value={dateData.start_date}
                  onChange={handleInputDateChange}
                  required
                />
              </InputGroup>
            </Col>
            <Col md={6} lg={3} sm={12} className="my-0.5 mx-2">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">To</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="end_date"
                  value={dateData.end_date}
                  onChange={handleInputDateChange}
                  required
                />
              </InputGroup>
            </Col>
            <Button
              variant="primary"
              onClick={() => getStockList()}
            >
              <i className="fa fa-search"></i> Search
            </Button>
          </div>
          <Button
            type="button"
            variant="success"
            className="btn btn-primary mb-2"
            onClick={exportToExcel}
          >
            <i className="fa fa-file-excel"></i> Export to Excel
          </Button>
        </Card.Header>
        <div className="card">
          <div className="card-body">
            <AgGridComponent
              columnDefs={columnDefs}
              rowData={stoneList}
              onFilterChanged={onFilterChanged}
              pinnedBottomRowData={pinnedBottomRowData}
              paginationPageSize={100}
              height="70vh"
            />
          </div>
        </div>
      </Card>

      <Modal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        size="xl"
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add ClarityMaster</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <Form.Group>
              <ClarityMasterDD
                clarityMasterID={selectDDClarityMasterID}
              ></ClarityMasterDD>
            </Form.Group>
          }
        </Modal.Body>
      </Modal>

      <Modal
        show={showColorPopup}
        onHide={() => setShowColorPopup(false)}
        size="xl"
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add ColorMaster</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <Form.Group>
              <ColorMasterDD
                colorMasterID={selectDDcolorMasterID}
              ></ColorMasterDD>
            </Form.Group>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}
