import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import api from "../../Utils/api";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import ImageUploadModalComponent from "../../Components/Common/ImageUploadModalComponent";
import ImageViewerModalComponent from "../../Components/Common/ImageViewerModalComponent";
// import { addDecimalPlaces } from "../../Utils/commonfunctions";
import FindStone from "../../Components/Common/FindStone";
import { useStonesContext } from "../../contextFile/StoneContext/StonesContext";
import {
  // PrintStoneSticker,
  // PrintBatchSticker,
  // PrintStoneStickerFromBatchNo,
  getCurrentDateTime,
} from "../../Utils/commonfunctions";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { GetPre7DayDate, getCurrentDate } from "../../Utils/commonfunctions";
import { Button, Col, InputGroup, Form } from "react-bootstrap";

// import ReactDOM from "react-dom";

export default function IssueToMachine() {
  // State variables
  const { listStones, setListStones } = useStonesContext();
  const { setFindNo } = useStonesContext();
  const { setBatchAvgList } = useStonesContext();
  const [machineOptions, setMachineOptions] = useState([]);
  const [machineID, setMachineID] = useState(null);
  const [issueToMachineList, setIssueToMachineList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [issueToWorkerNoa, setIssueToWorkerNoa] = useState(0);
  const [machineStartTime, setMachineStartTime] = useState("");

  //Insert Image
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  //Image Viewer
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedBatchNo, setSelectedBatchNo] = useState(null);

  //Btn Loader
  const [showLoader, setShowLoader] = useState(false);

  const emtSearchFormData = {
    start_date: GetPre7DayDate(),
    end_date: getCurrentDate(),
  };

  // for date range picker 
  const [dateData, setDateData] = useState(emtSearchFormData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDateData({ ...dateData, [name]: value });
  };

  useEffect(() => {
    getAllMachine();
    getIssueToMachine();
  }, []);

  // Fetch all departments from the API
  const getAllMachine = () => {
    api
      .get("Machine/MachineListForIssue")
      .then((result) => {
        setMachineOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getIssueToMachine = () => {
    setShowLoader(true);
    api
      .get(`IssueToMachine/CurrentIssueToWorkOfUser?START_DATE=${dateData.start_date}&END_DATE=${dateData.end_date}`)
      .then((result) => {
        setIssueToMachineList(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setShowLoader(false); // Hide loader
      });
  };

  // Handle department dropdown selection
  const ddMachineList = (selectedOption) => {
    setMachineID(selectedOption);
  };

  // Insert data when Save button is clicked
  const InsertIssueToMachine = () => {
    setShowLoader(true);
    if (machineID === null) {
      Swal.fire("Error", "Please Select Machine", "error");
      return;
    }
    const data = {
      machineID: machineID?.value,
      ListStone: listStones,
    };
    api
      .post(`IssueToMachine/Insert`, data)
      .then((result) => {
        toast.success(result.data.message);
        // Reset the form fields after a successful API call
        setFindNo("");
        setListStones([]);
        setBatchAvgList([]);
        setMachineID(null);
        getIssueToMachine();
        getAllMachine();
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      })
      .finally(() => {
        setShowLoader(false); // Hide loader
      });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleStartClick = (no) => {
    // Show the Bootstrap modal
    setIssueToWorkerNoa(no);
    setIsModalOpen(true);
    setMachineStartTime(getCurrentDateTime);
  };

  const InsertMachineTime = () => {
    setShowLoader(true);
    const Data = {
      issueToWorkerNo: issueToWorkerNoa,
      machineTimeStart: machineStartTime,
    };
    api
      .post(`IssueToMachine/MachineStartTime`, Data)
      .then((result) => {
        toast.success(result.data.message);
        // Reset the form fields after a successful API call
        setIsModalOpen(false);
        getIssueToMachine();
        setIssueToWorkerNoa(0);
        setMachineStartTime("");
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      })
      .finally(() => {
        setShowLoader(false); // Hide loader
      });
  };

  // Insert Image
  const popUpForImage = (data) => {
    setImageModalOpen(true);
    setModalData(data);
  };

  const closeModal = () => {
    setImageModalOpen(false);
  };
  // Insert Image End

  //Image Viewer Start
  const popUpForViewImage = (batchNo) => {
    setSelectedBatchNo(batchNo);
    setIsImageViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsImageViewerOpen(false);
    setSelectedBatchNo(0);
  };
  //Image Viewer End

  // export exel function
  const exportToExcel = () => {
    setShowLoader(true);

    setTimeout(async () => {
      try {
        const fileName = "IssueMachineList.xlsx";

        // Format the data to be exported, including the date and time
        const formattedData = issueToMachineList.map((item) => {
          return {
            Date: item.dateTime,
            BatchNo: item.batchNo,
            MachineNo: item.machineNo,
            totalPcs: item.totalPcs,
            issueToWorkerNo: item.issueToWorkerNo,
            avgtotalLength: item.avgtotalLength,
            avgtotalWidth: item.avgtotalWidth,
            avgtotalHeight: item.avgtotalHeight,
            totalWeight: item.totalWeight,
          };
        });

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Issue Machine List");

        // Set up the columns
        worksheet.columns = [
          { header: "Date", key: "Date", width: 30 },
          { header: "Batch No", key: "BatchNo", width: 15 },
          { header: "Machine No", key: "MachineNo", width: 15 },
          { header: "Total PCS", key: "totalPcs", width: 10 },
          { header: "Worker No", key: "issueToWorkerNo", width: 20 },
          { header: "Avg Length", key: "avgtotalLength", width: 15 },
          { header: "Avg Width", key: "avgtotalWidth", width: 15 },
          { header: "Avg Height", key: "avgtotalHeight", width: 15 },
          { header: "Total Weight", key: "totalWeight", width: 15 },
        ];

        // Add the rows to the worksheet
        worksheet.addRows(formattedData);

        // Style the header row
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF4F81BD" },
          };
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });

        // Adjust column widths based on content length
        worksheet.columns.forEach((column) => {
          let maxLength = column.header.length;
          formattedData.forEach((row) => {
            const cellValue = row[column.key];
            if (cellValue) {
              maxLength = Math.max(maxLength, cellValue.toString().length);
            }
          });
          column.width = maxLength + 2; // Add padding
        });

        // Export the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
        // Handle any error here, such as showing an error message to the user
      } finally {
        setShowLoader(false);
      }
    }, 1000);
  };

  const columnDefs = [
    {
      headerName: "Date Time",
      field: "dateTime",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Batch No",
      field: "batchNo",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      // cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Machine No",
      field: "machineNo",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      width: "140px",
    },
    {
      headerName: "Total Pcs",
      field: "totalPcs",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      width: 120,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      // cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start",
      field: "issueToWorkerNo",
      headerClass: "center-text widthTableColum",
      width: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: ({ value, data }) => {
        if (data.machineTime) {
          return <span>{data.machineTime}</span>;
        } else {
          return (
            <button
              className="btn btn-success mb-2"
              onClick={() => handleStartClick(value)}
            >
              Start
            </button>
          );
        }
      },
    },
    {
      headerName: "Avg Total Length",
      field: "avgtotalLength",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Avg Total Width",
      field: "avgtotalWidth",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      // cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Avg Total Height",
      field: "avgtotalHeight",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      // cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Total Weight",
      field: "totalWeight",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      // cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Attached",
      field: "batchNo",
      width: 140,
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="attach-outline"
          class="iconsFonts attachIcon"
          onClick={() => popUpForImage(data)}
        ></ion-icon>
      ),
    },
    {
      headerName: "View",
      field: "batchNo",
      width: 150,
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="eye-outline"
          class="iconsFonts attachIcon"
          onClick={() => popUpForViewImage(data.batchNo)}
        ></ion-icon>
      ),
    },
    // {
    //   headerName: "Print",
    //   width: 100,
    //   field: "stoneNo",
    //   cellStyle: { textAlign: "center" },
    //   headerClass: "centered-header",
    //   cellRenderer: ({ value, data }) => {
    //     if (value.split("-")[0] === "LOT") {
    //       return (
    //         <ion-icon
    //           name="print-outline"
    //           class="iconsFonts"
    //           onClick={() =>
    //             PrintBatchSticker(data.batchNo, data.stoneProcessNo)
    //           }
    //         ></ion-icon>
    //       );
    //     } else {
    //       return (
    //         <ion-icon
    //           name="print-outline"
    //           class="iconsFonts"
    //           onClick={() =>
    //             PrintStoneSticker(value)
    //           }
    //         ></ion-icon>
    //       );
    //     }
    //   },
    // },
    // {
    //   headerName: "StonesByBatch",
    //   width: 100,
    //   field: "stoneNo",
    //   cellStyle: { textAlign: "center" },
    //   headerClass: "centered-header",
    //   cellRenderer: ({ value }) => {
    //     if (value.split("-")[0] === "LOT") {
    //       return (
    //         <ion-icon
    //           name="print-outline"
    //           class="iconsFonts"
    //           onClick={() => PrintStoneStickerFromBatchNo(value)}
    //         ></ion-icon>
    //       );
    //     } else {
    //       return (
    //         <p>
    //           {" "}
    //           <b>Not!</b>
    //         </p>
    //       );
    //     }
    //   },
    // },
  ];

  return (
    <>
      {showLoader && (
        <>
          <div className="overlay"></div>
          <div className="overlay-content text-center">
            <Spinner animation="" variant="primary">
              <span class="loader"></span>
            </Spinner>
          </div>
        </>
      )}
      <ToastContainer />
      <FindStone
        IssueTo={"Issue To Machine"}
        findUrlForStone={
          "Stone/ByBatchNoOrSotneNoForCueerntUser?BatchNoOrStoneNo"
        }
        findUrlForBatch={
          "Stone/BatchAvgListByBatchNoOrStoneNo?BatchNoOrStoneNo"
        }
        showPartNo={false}
        showWithoutTextBox={false}
        showWithTextBox={true}
        showDLCAndLOSS={true}
      />
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="departmentDropdown">Machine:</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Select
                  id="departmentDropdown"
                  value={machineID}
                  onChange={ddMachineList}
                  options={machineOptions.map((option) => ({
                    value: option.machineID,
                    label: option.machineNo,
                  }))}
                  placeholder="--Select Machine--"
                />
              </div>
            </div>
          </div>
          <div className="card-footer margin_top10">
            <button
              type="button"
              className="align-items-center btn btn-primary mb-20px"
              onClick={InsertIssueToMachine}
              loading={showLoader}
              disabled={showLoader}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <span>Issue Machine List</span>
          <div className="d-flex justify-content-center flex-grow-1">
            <Col md={6} lg={3} sm={12} className="my-0.5">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">From</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="start_date"
                  value={dateData.start_date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Col md={6} lg={3} sm={12} className="my-0.5 mx-2">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">To</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="end_date"
                  value={dateData.end_date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Button
              variant="primary"
              onClick={() => getIssueToMachine()}
            >
              <i className="fa fa-search"></i> Search
            </Button>
          </div>

          <Button
            type="button"
            variant="success"
            className="btn btn-primary mb-2"
            onClick={exportToExcel}
          >
            <i className="fa fa-file-excel"></i> Export to Excel
          </Button>
        </div>
        <div className="card-body">
          {Array.isArray(issueToMachineList) ? (
            <AgGridComponent
              rowData={issueToMachineList}
              columnDefs={columnDefs}
              paginationPageSize={100}
              height="70vh"
            />
          ) : (
            <div>Loading or no data available</div>
          )}
        </div>
      </div>

      <div
        className={`modal fade ${isModalOpen ? "show d-block" : ""}`}
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden={!isModalOpen}
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Machine start</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <div className="row col-md-12">
                  <div className="col-md-2 col-sm-2 col-lg-2">
                    <label htmlFor="machineStartTime">Start Time:</label>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4">
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="machineStartTime"
                      placeholder="Enter Machine Start Time"
                      value={machineStartTime}
                      onChange={(e) => setMachineStartTime(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer margin_top10">
              <button
                type="button"
                className="btn btn-primary"
                onClick={InsertMachineTime}
              >
                Start Time
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Insert Image */}
      {imageModalOpen && (
        <ImageUploadModalComponent
          showModal={imageModalOpen}
          onClose={closeModal} // Pass the function to close the modal
          bNO={modalData.batchNo}
          sNO={modalData.stoneNo}
          referenceID={modalData.issueToWorkerID}
          type="Machine Start"
        />
      )}

      {/* Image Viewer */}
      {isImageViewerOpen && (
        <ImageViewerModalComponent
          showModal={isImageViewerOpen}
          onClose={closeImageViewer}
          referenceID={selectedBatchNo}
          refType="Machine Start"
        />
      )}
    </>
  );
}
