import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import api from "../../Utils/api";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import ImageUploadModalComponent from "../../Components/Common/ImageUploadModalComponent";
import ImageViewerModalComponent from "../../Components/Common/ImageViewerModalComponent";
import FindStone from "../../Components/Common/FindStone";
import { useStonesContext } from "../../contextFile/StoneContext/StonesContext";
// import {
//   PrintStoneSticker,
//   PrintBatchSticker,
//   PrintStoneStickerFromBatchNo,
// } from "../../Utils/commonfunctions";
import { Button, Col, InputGroup, Form } from "react-bootstrap";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { GetPre7DayDate, getCurrentDate } from "../../Utils/commonfunctions";

export default function IssueToWorker() {
  // State variables
  const { listStones, setListStones } = useStonesContext();
  const { setFindNo } = useStonesContext();
  const { setBatchAvgList } = useStonesContext();
  const [options, setOptions] = useState([]);
  const [workerOptions, setWorkerOptions] = useState([]);
  const [departmentID, setDepartmentID] = useState(null);
  const [workerID, setWorkerID] = useState(null);
  const [issueToWorkerList, setIssueToWorkerList] = useState([]);

  //Insert Image
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  //Image Viewer
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedBatchNo, setSelectedBatchNo] = useState(null);

  //Btn Loader
  const [showLoader, setShowLoader] = useState(false);


  const emtSearchFormData = {
    start_date: GetPre7DayDate(),
    end_date: getCurrentDate(),
  };

  // for date range picker 
  const [dateData, setDateData] = useState(emtSearchFormData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDateData({ ...dateData, [name]: value });
  };


  // Fetch all departments on component mount
  useEffect(() => {
    getAllDepartment();
    getIssueToWorkerList();
    if (departmentID !== null) {
      api
        .get(`Worker/DepartmentViseWorker?departmentID=${departmentID.value}`)
        .then((result) => {
          setWorkerOptions(result.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [departmentID]);

  // Fetch all departments from the API
  const getAllDepartment = () => {
    api
      .get("Department/All")
      .then((result) => {
        setOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Handle department dropdown selection
  const ddDepartmentNameList = (selectedOption) => {
    setDepartmentID(selectedOption);
  };

  // Handle user dropdown selection
  const ddWorkerNameList = (selectedOption) => {
    setWorkerID(selectedOption);
  };

  // Insert data when Save button is clicked
  const InsertIssueToWorker = () => {
    setShowLoader(true);
    const data = {
      DepartmentID: departmentID?.value,
      WorkerID: workerID?.value,
      ListStone: listStones,
    };
    api
      .post(`IssueToWorker/Insert`, data)
      .then((result) => {
        toast.success(result.data.message);
        // Reset the form fields after a successful API call
        setFindNo("");
        setListStones([]);
        setBatchAvgList([]);
        setDepartmentID(null);
        setWorkerID(null);
        setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
        setShowLoader(false);
      });
  };

  const getIssueToWorkerList = () => {
    api
      .get(`IssueToWorker/CurrentIssueToWorkOfUser?START_DATE=${dateData.start_date}&END_DATE=${dateData.end_date}`)
      .then((result) => {
        setIssueToWorkerList(result.data.data.list);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Insert Image
  const popUpForImage = (data) => {
    setImageModalOpen(true);
    setModalData(data);
  };

  const closeModal = () => {
    setImageModalOpen(false);
  };
  // Insert Image End

  //Image Viewer Start
  const popUpForViewImage = (batchNo) => {
    setSelectedBatchNo(batchNo);
    setIsImageViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsImageViewerOpen(false);
  };

  // export exel function
  const exportToExcel = () => {
    setShowLoader(true);
    setTimeout(() => {
      const fileName = "IssueToWorker.xlsx";

      // Format the data to be exported, including the date and time
      const formattedData = issueToWorkerList.map((item) => {
        return {
          WorkerName: item.workerName,
          stoneNo: item.stoneNo,
          pcs: item.pcs,
          Length: item.length,
          Width: item.width,
          Height: item.height,
          Weight: item.weight,
        };
      });

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Department Data");

      // Set up the columns
      worksheet.columns = [
        { header: "Worker Name", key: "WorkerName", width: 25 },
        { header: "Stone No", key: "stoneNo", width: 15 },
        { header: "PCS", key: "pcs", width: 10 },
        { header: "Length", key: "Length", width: 15 },
        { header: "Width", key: "Width", width: 15 },
        { header: "Height", key: "Height", width: 15 },
        { header: "Weight", key: "Weight", width: 15 },
      ];

      // Add the rows to the worksheet
      worksheet.addRows(formattedData);

      // Style the header row
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF4F81BD" },
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
      });

      // Adjust column widths based on content length
      worksheet.columns.forEach((column) => {
        let maxLength = column.header.length;
        formattedData.forEach((row) => {
          const cellValue = row[column.key];
          if (cellValue) {
            maxLength = Math.max(maxLength, cellValue.toString().length);
          }
        });
        column.width = maxLength + 2; // Add padding
      });

      // Export the Excel file
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      });

      setShowLoader(false);
    }, 1000);
  };

  const workerColumnDefs = [
    {
      headerName: "Worker Name",
      field: "workerName",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "StoneNo",
      field: "stoneNo",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Pcs",
      field: "pcs",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Length",
      field: "length",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Width",
      field: "width",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Height",
      field: "height",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Weight",
      field: "weight",
      flex: 1,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value, data }) => value.toFixed(2),
    },
    {
      headerName: "Attached",
      width: 100,
      field: "batchNo",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="attach-outline"
          class="iconsFonts attachIcon"
          onClick={() => popUpForImage(data)}
        ></ion-icon>
      ),
    },
    {
      headerName: "View",
      width: 100,
      field: "batchNo",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value, data }) => (
        <ion-icon
          name="eye-outline"
          class="iconsFonts attachIcon"
          onClick={() => popUpForViewImage(data.batchNo)}
        ></ion-icon>
      ),
    },
    // {
    //   headerName: "Print",
    //   width: 100,
    //   field: "stoneNo",
    //   cellStyle: { textAlign: "center" },
    //   headerClass: "centered-header",
    //   cellRenderer: ({ value }) => {
    //     ;
    //     if (value.split("-")[0] === "LOT") {
    //       return (
    //         <ion-icon
    //           name="print-outline"
    //           class="iconsFonts"
    //           onClick={() => PrintBatchSticker(value)}
    //         ></ion-icon>
    //       );
    //     } else {
    //       return (
    //         <ion-icon
    //           name="print-outline"
    //           class="iconsFonts"
    //           onClick={() => PrintStoneSticker(value)}
    //         ></ion-icon>
    //       );
    //     }
    //   },
    // },
    // {
    //   headerName: "StonesByBatch",
    //   width: 100,
    //   field: "stoneNo",
    //   cellStyle: { textAlign: "center" },
    //   headerClass: "centered-header",
    //   cellRenderer: ({ value }) => {
    //     ;
    //     if (value.split("-")[0] === "LOT") {
    //       return (
    //         <ion-icon
    //           name="print-outline"
    //           class="iconsFonts"
    //           onClick={() => PrintStoneStickerFromBatchNo(value)}
    //         ></ion-icon>
    //       );
    //     } else {
    //       return (
    //         <p>
    //           {" "}
    //           <b>Not!</b>
    //         </p>
    //       );
    //     }
    //   },
    // },
  ];

  return (
    <>
      <ToastContainer />
      <FindStone
        IssueTo={"Issue To Worker"}
        findUrlForStone={
          "Stone/ByBatchNoOrSotneNoForCueerntUser?BatchNoOrStoneNo"
        }
        findUrlForBatch={
          "Stone/BatchAvgListByBatchNoOrStoneNo?BatchNoOrStoneNo"
        }
        showPartNo={true}
        showWithoutTextBox={true}
        showWithTextBox={false}
        showDLCAndLOSS={true}
        showTotal={false}
      />

      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="departmentDropdown">Department:</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Select
                  id="departmentDropdown"
                  value={departmentID}
                  onChange={ddDepartmentNameList}
                  options={options.map((option) => ({
                    value: option.departmentID,
                    label: option.departmentName,
                  }))}
                  placeholder="--Select Department--"
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="workerDropdown">Worker:</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Select
                  id="workerDropdown"
                  value={workerID}
                  onChange={ddWorkerNameList}
                  options={workerOptions.map((option) => ({
                    value: option.workerID,
                    label: option.workerName,
                  }))}
                  placeholder="--Select Worker--"
                />
              </div>
            </div>
          </div>
          <div className="card-footer margin_top10">
            <button
              type="button"
              className="align-items-center btn btn-primary mb-20px"
              onClick={InsertIssueToWorker}
              loading={showLoader}
              disabled={showLoader}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div className="card mt-10">
        <div className="card-header d-flex justify-content-between">
          <span>Worker Stock List</span>
          <div className="d-flex justify-content-center flex-grow-1">
            <Col md={6} lg={3} sm={12} className="my-0.5">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">From</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="start_date"
                  value={dateData.start_date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Col md={6} lg={3} sm={12} className="my-0.5 mx-2">
              <InputGroup className="PolicySelectDateInputGroup">
                <InputGroup.Text id="basic-addon1">To</InputGroup.Text>
                <Form.Control
                  type="date"
                  name="end_date"
                  value={dateData.end_date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Col>
            <Button
              variant="primary"
              onClick={() => getIssueToWorkerList()}
            >
              <i className="fa fa-search"></i> Search
            </Button>
          </div>
          <Button
            type="button"
            variant="success"
            className="btn btn-primary mb-2"
            onClick={exportToExcel}
          >
            <i className="fa fa-file-excel"></i> Export to Excel
          </Button>
        </div>
        <div className="card-body">
          <AgGridComponent
            rowData={issueToWorkerList}
            columnDefs={workerColumnDefs}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
            paginationPageSize={100}
            height="70vh"
          />
        </div>
      </div>
      {/* Insert Image */}
      {imageModalOpen && (
        <ImageUploadModalComponent
          showModal={imageModalOpen}
          onClose={closeModal} // Pass the function to close the modal
          bNO={modalData.batchNo}
          sNO={modalData.stoneNo}
          referenceID={modalData.issueToWorkerID}
          type="Machine Start"
        />
      )}

      {/* Image Viewer */}
      {isImageViewerOpen && (
        <ImageViewerModalComponent
          showModal={isImageViewerOpen}
          onClose={closeImageViewer}
          referenceID={selectedBatchNo}
          refType="Machine Start"
        />
      )}
    </>
  );
}
