import React from "react";
import api from "./api";
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";
import { ISOToDateTime } from "../Components/Common/Common";

// import BarcodeReact from "react-barcode";

export function convertDateFormatWithOutTime(dateString) {
  const dateObject = new Date(dateString);

  // Extract date, month, year, hours, and minutes
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getFullYear();
  const formattedDate = `${day}/${month}/${year} `;

  return formattedDate;
}

// Function to round a number to a specified decimal places
export const addDecimalPlaces = (number, decimalPlaces) => {
  // const roundedNumber = +number.toFixed(decimalPlaces); // Convert to number to remove trailing zeros
  // return roundedNumber.toString();
  switch (decimalPlaces) {
    case 2:
      return parseFloat((Math.round(number * 100) / 100).toFixed(2));
    case 3:
      return parseFloat((Math.round(number * 1000) / 1000).toFixed(3));
    case 4:
      return parseFloat((Math.round(number * 1000) / 1000).toFixed(4));
    default:
      return 0;
  }
};



// SINGLE STONE STICKER
export const PrintStoneSticker = (no, processNo) => {
  debugger
  api
    .get(`Stone/PrintStoneSticker?StoneNo=${no}&StoneProcessNo=${processNo}`)
    .then((result) => {
      let StoneDetailsForPrint = [];
      StoneDetailsForPrint.push(result.data.data);
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      iframeDocument.write(
        '<html><head><style>@page{margin: 0;}</style><title>Report</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"></head><body>'
      );
      ReactDOM.render(
        <>
          {StoneDetailsForPrint.map((stoneDetail, index) => (
            <React.Fragment key={index}>
              <PrintPreviewStoneDetails stoneDetail={stoneDetail} />
            </React.Fragment>
          ))}
        </>,
        iframeDocument.body
      );
      iframeDocument.write("</body></html>");
      iframeDocument.close();
      setTimeout(() => {
        iframe.contentWindow.print();
      }, 400);
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);

      // const printWindow = window.open("", "_blank");
      // printWindow.document.write(
      //   '<html><head><style>@page{margin: 0;}</style><title>Stone Sticker</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"></head><body>'
      // );
      // ReactDOM.render(
      //   // <PrintPreviewStone stoneDetails={StoneDetailsForPrint} />,
      //   <>
      //     {StoneDetailsForPrint.map((stoneDetail, index) => (
      //       <React.Fragment key={index}>
      //         <PrintPreviewStoneDetails stoneDetail={stoneDetail} />
      //       </React.Fragment>
      //     ))}
      //   </>,
      //   printWindow.document.body
      // );
      // printWindow.document.write("</body></html>");
      // setTimeout(() => {
      //   printWindow.print();
      //   printWindow.close();
      // }, 200);
    })
    .catch((error) => {
      console.log(error);
    });

  // Component to display stone details for printing
  // const PrintPreviewStone = ({ stoneDetails }) => {
  //   return (
  //     <>
  //       {stoneDetails.map((stoneDetail, index) => (
  //         <React.Fragment key={index}>
  //           <div style={{
  //             display: "flex",
  //             position: "relative",
  //             width: "186px",
  //             height: "92px",
  //             fontSize: "10px",
  //             lineHeight: "20px",
  //             padding: "4px",
  //             breakInside: "avoid",
  //             fontWeight: "bold",
  //             marginLeft: "14px"
  //           }}
  //           >
  //             <div style={{
  //               width: "130px",
  //             }}>
  //               <div>
  //                 <span>
  //                   {stoneDetail.stoneType}/{stoneDetail.stoneNo}
  //                 </span>
  //               </div>
  //               <div>
  //                 <span>
  //                   <strong> L X W: </strong> {stoneDetail.length.toFixed(2)} X{" "}
  //                   {stoneDetail.width.toFixed(2)}
  //                 </span>
  //               </div>
  //               <div>
  //                 <span>
  //                   <strong> HI: </strong> {stoneDetail.height.toFixed(2)} <strong>{" "} WT:</strong> {stoneDetail.weight.toFixed(2)} ct.
  //                 </span>
  //               </div>
  //               <div>
  //                 <span>
  //                   {ISOToDateTime(stoneDetail.createdDatetime)}
  //                 </span>
  //               </div>
  //             </div>
  //             <div style={{ width: "58px" }}>
  //               <div style={{ textAlign: "end" }}>
  //                 <span>
  //                   <strong> Pcs: </strong> {stoneDetail.pcs}
  //                 </span>
  //               </div>
  //               <div style={{ height: "auto", margin: "0 auto", maxWidth: 58, width: "100%" }} >
  //                 <QRCode style={{ height: "auto", maxWidth: "100%", width: "100%" }} value={stoneDetail.stoneNo} />
  //               </div>
  //             </div>
  //           </div>
  //         </React.Fragment>
  //       ))}
  //     </>
  //   );
  // };
};

//STONE MULTI STICKER
export const PrintStoneStickerFromBatchNo = (no, processNo) => {
  api
    .get(
      `Stone/PrintStoneStickerFromBatchNo?BatchNo=${no}&StoneProcessNo=${processNo}`
    )
    .then((result) => {
      const StoneDetailsForPrint = result.data.data;
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      iframeDocument.write(
        '<html><head><style>@page{margin: 0;}</style><title>Report</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"></head><body>'
      );
      ReactDOM.render(
        <>
          {StoneDetailsForPrint.map((stoneDetail, index) => (
            <React.Fragment key={index}>
              <PrintPreviewStoneDetails stoneDetail={stoneDetail} />
            </React.Fragment>
          ))}
        </>,
        iframeDocument.body
      );
      iframeDocument.write("</body></html>");
      iframeDocument.close();
      setTimeout(() => {
        iframe.contentWindow.print();
      }, 400);
    })
    .catch((error) => {
      console.log(error);
    });

  // Component to display stone details for printing
  // const PrintPreviewMultiSticker = ({ stoneDetails }) => {
  //   return (
  //     <>
  //       {stoneDetails.map((stoneDetail, index) => (
  //         <React.Fragment key={index}>
  //           <div style={{
  //             display: "flex",
  //             position: "relative",
  //             width: "186px",
  //             height: "92px",
  //             fontSize: "10px",
  //             lineHeight: "20px",
  //             padding: "4px",
  //             breakInside: "avoid",
  //             fontWeight: "bold",
  //             marginLeft: "14px"
  //           }}
  //           >
  //             <div style={{
  //               width: "130px"
  //             }}>
  //               <div>
  //                 <span>
  //                   {stoneDetail.stoneType}/{stoneDetail.stoneNo}
  //                 </span>
  //               </div>
  //               <div>
  //                 <span>
  //                   <strong> L X W: </strong> {stoneDetail.length.toFixed(2)} X{" "}
  //                   {stoneDetail.width.toFixed(2)}
  //                 </span>
  //               </div>
  //               <div>
  //                 <span>
  //                   <strong> HI: </strong> {stoneDetail.height.toFixed(2)} <strong>{" "} WT:</strong> {stoneDetail.weight.toFixed(2)} ct.
  //                 </span>
  //               </div>
  //               <div>
  //                 <span>
  //                   {ISOToDateTime(stoneDetail.createdDatetime)}
  //                 </span>
  //               </div>
  //             </div>
  //             <div style={{ width: "58px" }}>
  //               <div style={{ textAlign: "end" }}>
  //                 <span>
  //                   <strong> Pcs: </strong> {stoneDetail.pcs}
  //                 </span>
  //               </div>
  //               <div style={{ height: "auto", margin: "0 auto", maxWidth: 54, width: "100%" }} >
  //                 <QRCode style={{ height: "auto", maxWidth: "100%", width: "100%" }} value={stoneDetail.stoneNo} />
  //               </div>
  //             </div>
  //           </div>
  //         </React.Fragment>
  //       ))}
  //     </>
  //   );
  // };
};

//BATCH STICKER
export const PrintBatchSticker = (no, processNo) => {
debugger
  api
    .get(`Stone/PrintBatchSticker?BatchNo=${no}&StoneProcessNo=${processNo}`)
    .then((result) => {
      let StoneDetailsForPrint = [];
      StoneDetailsForPrint.push(result.data.data);
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      iframeDocument.write(
        '<html><head><style>@page{margin: 0;}</style><title>Report</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"></head><body>'
      );
      ReactDOM.render(
        <>
          {StoneDetailsForPrint.map((stoneDetail, index) => (
            <React.Fragment key={index}>
              <PrintPreviewStoneDetails stoneDetail={stoneDetail} />
            </React.Fragment>
          ))}
        </>,
        iframeDocument.body
      );
      iframeDocument.write("</body></html>");
      iframeDocument.close();
      setTimeout(() => {
        iframe.contentWindow.print();
      }, 400);
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);
    })
    .catch((error) => {
      console.log(error);
    });

  // Component to display stone details for printing
  // const PrintPreviewBatch = ({ stoneDetails }) => {
  //   return (
  //     <>
  //       {stoneDetails.map((stoneDetail, index) => (
  //         <React.Fragment key={index}>
  //           <div style={{
  //             display: "flex",
  //             position: "relative",
  //             width: "186px",
  //             height: "92px",
  //             fontSize: "10px",
  //             lineHeight: stoneDetail.machineNo ? "16px" : "20px",
  //             padding: "4px",
  //             breakInside: "avoid",
  //             fontWeight: "bold",
  //             marginLeft: "14px"
  //           }}
  //           >
  //             <div style={{
  //               width: "130px",
  //             }}>
  //               <div>
  //                 <span>
  //                   {stoneDetail.stoneType}/{stoneDetail.stoneNo}
  //                   {stoneDetail.machineHours &&
  //                     <span>/CH</span>
  //                   }
  //                 </span>
  //               </div>
  //               <div>
  //                 <span>
  //                   <strong> L X W: </strong> {stoneDetail.length.toFixed(2)} X{" "}
  //                   {stoneDetail.width.toFixed(2)}
  //                 </span>
  //               </div>

  //               <div>
  //                 <strong> HI: </strong>  {stoneDetail.height.toFixed(2)} <strong>{" "} WT: </strong> {stoneDetail.weight.toFixed(2)} ct.
  //               </div>
  //               <div>
  //                 <span>
  //                   {ISOToDateTime(stoneDetail.createdDatetime)}
  //                 </span>
  //               </div>
  //               {stoneDetail.machineHours &&
  //                 <>
  //                   <div>
  //                     M:{stoneDetail.machineNo}/
  //                     Hr:{stoneDetail.machineHours}
  //                     {stoneDetail.machineGrowthRate > 0 &&
  //                       <strong>{" "}({stoneDetail.machineGrowthRate.toFixed(2)}) </strong>
  //                     }
  //                   </div>
  //                 </>
  //               }
  //             </div>
  //             <div style={{ width: "58px" }}>
  //               <div style={{ textAlign: "end" }}>
  //                 <span>
  //                   <strong> Pcs: </strong> {stoneDetail.pcs}
  //                 </span>
  //               </div>
  //               <div style={{ height: "auto", margin: "0 auto", maxWidth: 58, width: "100%" }} >
  //                 <QRCode style={{ height: "auto", maxWidth: "100%", width: "100%" }} value={stoneDetail.stoneNo} />
  //               </div>
  //               {stoneDetail.brokenItemCount > 0 &&
  //                 <span style={{ fontSize: "11px", lineHeight: "11px" }}>
  //                   <strong>Broken: </strong> {stoneDetail.brokenItemCount}
  //                 </span>
  //               }
  //             </div>
  //           </div>
  //         </React.Fragment>
  //       ))}
  //     </>
  //   );
  // };
};

// Common component for stone details printing
export const PrintPreviewStoneDetails = ({ stoneDetail }) => {
  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        width: "186px",
        height: "92px",
        fontSize: "11px",
        lineHeight: stoneDetail.machineNo ? "16px" : "20px",
        padding: "4px",
        breakInside: "avoid",
        fontWeight: "bold",
        marginLeft: "14px",
      }}
    >
      <div
        style={{
          width: "140px",
        }}
      >
        <div>
          <span>
            {stoneDetail.stoneType}/{stoneDetail.stoneNo}
            {stoneDetail.machineHours && <span>/CH</span>}
          </span>
        </div>
        <div>
          <span>
            <strong> L X W: </strong> {stoneDetail.length.toFixed(2)} X{" "}
            {stoneDetail.width.toFixed(2)}
          </span>
        </div>
        <div>
          <strong> HI: </strong> {stoneDetail.height.toFixed(2)}{" "}
          <strong> WT: </strong> {stoneDetail.weight.toFixed(2)} ct.
        </div>
        <div>
          <span>{ISOToDateTime(stoneDetail.createdDatetime)}</span>
        </div>
        {stoneDetail.machineHours != null &&
          stoneDetail.machineHours !== undefined && (
            <>
              <div>
                M:{stoneDetail.machineNo}/ Hr:{stoneDetail.machineHours}
                {stoneDetail.machineGrowthRate > 0 && (
                  <strong> ({stoneDetail.machineGrowthRate}) </strong>
                )}
              </div>
            </>
          )}
      </div>
      <div style={{ width: "48px" }}>
        <div style={{ textAlign: "end" }}>
          <span>
            <strong> Pcs: </strong> {stoneDetail.pcs}
          </span>
        </div>
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 48,
            width: "100%",
          }}
        >
          <QRCode
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={stoneDetail.stoneNo}
          />
        </div>
        {stoneDetail.brokenItemCount > 0 && (
          <div style={{ textAlign: "end" }}>
            <span style={{ fontSize: "11px", lineHeight: "11px" }}>
              <strong>Br: </strong> {stoneDetail.brokenItemCount}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export const PrintBagSticker = (no, processNo) => {
  api
    .get(`Stone/PrintStoneSticker?StoneNo=${no}&StoneProcessNo=${processNo}`)
    .then((result) => {
      let StoneDetailsForPrint = [];
      StoneDetailsForPrint.push(result.data.data);
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      iframeDocument.write(
        '<html><head><style>@page{margin: 0;}</style><title>Report</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"></head><body>'
      );
      ReactDOM.render(
        <>
          {StoneDetailsForPrint.map((stoneDetail, index) => (
            <React.Fragment key={index}>
              <PrintPreviewBagDetails BagDetail={stoneDetail} />
            </React.Fragment>
          ))}
        </>,
        iframeDocument.body
      );
      iframeDocument.write("</body></html>");
      iframeDocument.close();
      setTimeout(() => {
        iframe.contentWindow.print();
      }, 400);
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const PrintPreviewBagDetails = ({ BagDetail }) => {
  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        width: "186px",
        height: "92px",
        fontSize: "11px",
        lineHeight: BagDetail.machineNo ? "16px" : "20px",
        padding: "4px",
        breakInside: "avoid",
        fontWeight: "bold",
        marginLeft: "14px",
      }}
    >
      <div
        style={{
          width: "140px",
        }}
      >
        <div>
          <span>
            {BagDetail.stoneType}/{BagDetail.stoneNo}
          </span>
        </div>
        <div></div>
        <div>
          <strong> HI: </strong> {BagDetail.height.toFixed(2)}{" "}
          <strong> WT: </strong> {BagDetail.weight.toFixed(2)} ct.
        </div>
        <div>
          <span>{ISOToDateTime(BagDetail.createdDatetime)}</span>
        </div>
        {BagDetail.shapeAndColorRemarks && (
          <div>
            <span>{BagDetail.shapeAndColorRemarks}</span>
          </div>
        )}
         {BagDetail.weightRemarks && (
          <div>
            <span>{BagDetail.weightRemarks}</span>
          </div>
        )}
      </div>
      <div style={{ width: "48px" }}>
        <div style={{ textAlign: "end" }}>
          <span>
            <strong> Pcs: </strong> {BagDetail.pcs}
          </span>
        </div>
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 48,
            width: "100%",
          }}
        >
          <QRCode
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={BagDetail.stoneNo}
          />
        </div>
        {/* {BagDetail.brokenItemCount > 0 &&
          <div style={{ textAlign: "end" }}>
            <span style={{ fontSize: "11px", lineHeight: "11px" }}>
              <strong>Br: </strong> {BagDetail.brokenItemCount}
            </span>
          </div>
        } */}
      </div>
    </div>
  );
};

export const handleKeyDown = (e) => {
  if (e.which === 13) {
    if (e.target.type !== "textarea") {
      const formControls = Array.from(
        document.querySelectorAll(".form-control")
      );
      const index = formControls.indexOf(e.target) + 1;
      if (index < formControls.length) {
        const nextInput = formControls[index];
        if (nextInput) {
          nextInput.focus();
          nextInput.select();
        }
      } else {
        // If there's no next input, simulate a click on a button
        const button = document.querySelector("button"); // Adjust the selector based on your HTML structure
        if (button) {
          button.click(); // Simulate a button click
        }
      }
    }
  } else if (e.which === 9) {
    if (e.target.type !== "textarea") {
      const formControls = Array.from(
        document.querySelectorAll(".form-control")
      );
      const index = formControls.indexOf(e.target);
      if (index < formControls.length) {
        const nextInput = formControls[index];
        if (nextInput) {
          nextInput.focus();
          nextInput.select();
        }
      }
    }
  }
};

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getCurrentDateTime = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const hours = String(today.getHours()).padStart(2, "0");
  const minutes = String(today.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
};


export const filterParams = {
  comparator: function(filterLocalDateAtMidnight, cellValue) {
    if (!cellValue) return -1;
    const cellDate = new Date(cellValue);
    if (isNaN(cellDate)) return -1;
    const cellDateMidnight = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());
    const filterDate = new Date(filterLocalDateAtMidnight.getFullYear(), filterLocalDateAtMidnight.getMonth(), filterLocalDateAtMidnight.getDate());
    if (filterDate.getTime() === cellDateMidnight.getTime()) {
      return 0;
    }
    if (cellDateMidnight < filterDate) {
      return -1;
    }
    if (cellDateMidnight > filterDate) {
      return 1;
    }
    return 0;
  },
  minValidYear: new Date().getFullYear() - 100, // Current year minus 100
  maxValidYear: new Date().getFullYear() + 100,
  inRangeFloatingFilterDateFormat: "Do MMM YYYY",
};


export const GetPre30DayDate = () => {
  const today = new Date();
  const pre30DayDate = new Date(today.setDate(today.getDate() - 30));

  const year = pre30DayDate.getFullYear();
  const month = String(pre30DayDate.getMonth() + 1).padStart(2, "0");
  const day = String(pre30DayDate.getDate()).padStart(2, "0");
  
  return `${year}-${month}-${day}`;
};


export const GetPre7DayDate = () => {
  const today = new Date();
  const pre7DayDate = new Date(today.setDate(today.getDate() - 7));

  const year = pre7DayDate.getFullYear();
  const month = String(pre7DayDate.getMonth() + 1).padStart(2, "0");
  const day = String(pre7DayDate.getDate()).padStart(2, "0");
  
  return `${year}-${month}-${day}`;
};